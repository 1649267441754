export const HealthIconSVG = () => {
    return (
        <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3633_744)">
                <path d="M19 13.19C19 13.2467 18.9935 13.3033 18.9806 13.3586C18.9935 13.5484 19 13.7445 19 13.9471C19 19.0988 9.5 22 9.5 22C9.5 22 0 19.0988 0 13.9455C0 13.744 0.00647915 13.5478 0.0194374 13.357C0.00662528 13.3022 0.000105445 13.2462 0 13.19V1.75551C0.000427965 1.55527 0.0816264 1.36334 0.225823 1.22175C0.37002 1.08015 0.56547 1.00042 0.769395 1H18.2306C18.4345 1.00042 18.63 1.08015 18.7742 1.22175C18.9184 1.36334 18.9996 1.55527 19 1.75551V13.19ZM15.2891 9.1277C15.2887 8.92745 15.2075 8.73553 15.0633 8.59394C14.9191 8.45234 14.7236 8.37261 14.5197 8.37219H11.4583V5.36605C11.4575 5.16636 11.3763 4.97508 11.2325 4.83388C11.0887 4.69267 10.8939 4.61297 10.6905 4.61213H8.30946C8.10554 4.61255 7.91009 4.69229 7.76589 4.83388C7.6217 4.97548 7.5405 5.1674 7.54007 5.36764V8.37219H4.48031C4.27638 8.37261 4.08093 8.45234 3.93674 8.59394C3.79254 8.73553 3.71134 8.92745 3.71091 9.1277V11.4658C3.71177 11.6658 3.79315 11.8573 3.93731 11.9985C4.08146 12.1398 4.27666 12.2193 4.48031 12.2197H7.54169V15.2243C7.54211 15.4242 7.62309 15.6159 7.76694 15.7575C7.91079 15.899 8.10582 15.9789 8.30946 15.9798H10.6905C10.8945 15.9794 11.0899 15.8996 11.2341 15.758C11.3783 15.6164 11.4595 15.4245 11.4599 15.2243V12.2197H14.5197C14.7236 12.2193 14.9191 12.1396 15.0633 11.998C15.2075 11.8564 15.2887 11.6645 15.2891 11.4642V9.1277Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_3633_744">
                    <rect width="19" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}