import { mute, set_mute } from "../../App";
import { EventBusNames } from "../constants";
import { EventBus } from "../EventBus";
import { AudioController } from "./AudioController";

export class GDSDKController {
    public static instance: GDSDKController;
    public static track = true;

    public static getInstance() {
        if (this.instance) {
            return this.instance;
        } else {
            this.instance = new GDSDKController();

            return this.instance;
        }
    }

    gdsdk: any;
    oldMute = mute();

    private constructor() {
        (window as any).GDSDKController = GDSDKController;

        this.gdsdk = (window as any).gdsdk;
    }

    preloadRewardedAd() {
        return this?.gdsdk?.preloadAd?.('rewarded');
    }
    showAd() {
        return new Promise<void>((res, rej) => {
            if (this?.gdsdk) {

                this?.gdsdk?.showAd?.()?.then?.(() => {
                    res();
                })?.catch?.(() => {
                    res();
                });
            } else {
                res();
            }
        })
    }
    showRewardedAd() {
        return new Promise<void>((res, rej) => {
            if (this?.gdsdk) {
                this?.gdsdk?.showAd?.('rewarded')?.then?.(() => {
                    // this.preloadRewardedAd();
                    res();
                })?.catch?.(() => {
                    // this.preloadRewardedAd();
                    res();
                });
            } else {
                res();
            }
        });
    }

    // showAd(type?: 'rewarded-ad') {
    //     // this?.gdsdk?.showAd?.(type);
    //     this?.gdsdk?.showBanner?.();
    // }

    adStart() {
        this.oldMute = mute();
        AudioController.getInstance().mute();

        console.log('GMSK:', 'muted');

        return new Promise<void>((res, rej) => {
            EventBus.emit(EventBusNames.AD_START);
            res();
        });
    }
    adDone() {
        if (this.oldMute) {
            AudioController.getInstance().mute();
        } else {
            AudioController.getInstance().unmute();
        }

        console.log('GMSK:', 'muted:', this.oldMute);

        return new Promise<void>((res, rej) => {
            EventBus.emit(EventBusNames.AD_DONE);
            res();
        });
    }
}
