export const InfoIconSvg = () => {
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3436_4343)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.6693 12.4987C23.6693 18.942 18.4459 24.1654 12.0026 24.1654C5.55928 24.1654 0.335938 18.942 0.335938 12.4987C0.335938 6.05537 5.55928 0.832031 12.0026 0.832031C18.4459 0.832031 23.6693 6.05537 23.6693 12.4987ZM12.0026 19.207C12.4858 19.207 12.8776 18.8153 12.8776 18.332V11.332C12.8776 10.8488 12.4858 10.457 12.0026 10.457C11.5194 10.457 11.1276 10.8488 11.1276 11.332V18.332C11.1276 18.8153 11.5194 19.207 12.0026 19.207ZM12.0026 6.66536C12.647 6.66536 13.1693 7.18771 13.1693 7.83203C13.1693 8.47636 12.647 8.9987 12.0026 8.9987C11.3583 8.9987 10.8359 8.47636 10.8359 7.83203C10.8359 7.18771 11.3583 6.66536 12.0026 6.66536Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_3436_4343">
                <rect width="24" height="25" fill="white" />
            </clipPath>
        </defs>
    </svg>

}