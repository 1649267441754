export const SpeedIconSVG = () => {
    return (
        <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3638_1402)">
                <path d="M0.883751 12.3857C0.799757 12.4448 0.701115 12.4798 0.598597 12.4867C0.496079 12.4937 0.393627 12.4723 0.302427 12.425C0.211228 12.3776 0.134788 12.3061 0.0814543 12.2183C0.0281205 12.1305 -5.64712e-05 12.0297 8.49712e-08 11.927V0.63333C-5.64712e-05 0.530578 0.0281205 0.429786 0.0814543 0.34196C0.134788 0.254133 0.211228 0.182649 0.302427 0.135313C0.393627 0.0879774 0.496079 0.0666101 0.598597 0.0735441C0.701115 0.080478 0.799757 0.115447 0.883751 0.174632L8.88349 5.82256C8.95663 5.87437 9.01628 5.94296 9.05745 6.02257C9.09862 6.10219 9.1201 6.19051 9.1201 6.28014C9.1201 6.36977 9.09862 6.45809 9.05745 6.53771C9.01628 6.61732 8.95663 6.68591 8.88349 6.73772L0.883751 12.3857ZM10.0936 0.63333C10.0935 0.530578 10.1217 0.429786 10.1751 0.34196C10.2284 0.254133 10.3048 0.182649 10.396 0.135313C10.4872 0.0879774 10.5897 0.0666101 10.6922 0.0735441C10.7947 0.080478 10.8934 0.115447 10.9774 0.174632L18.9771 5.82256C19.0502 5.87437 19.1099 5.94296 19.1511 6.02257C19.1922 6.10219 19.2137 6.19051 19.2137 6.28014C19.2137 6.36977 19.1922 6.45809 19.1511 6.53771C19.1099 6.61732 19.0502 6.68591 18.9771 6.73772L10.9774 12.3857C10.8934 12.4448 10.7947 12.4798 10.6922 12.4867C10.5897 12.4937 10.4872 12.4723 10.396 12.425C10.3048 12.3776 10.2284 12.3061 10.1751 12.2183C10.1217 12.1305 10.0935 12.0297 10.0936 11.927V0.63333Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_3638_1402">
                    <rect width="19.29" height="12.5609" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}