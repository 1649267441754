export const audio_repo: { [key in AUDIO_NAMES as string]: AudioBuffer} = {};

export enum AUDIO_NAMES {
    HELP_WELCOME = "HELP_WELCOME",
    HELP_MOVEMENT = "HELP_MOVEMENT",
    HELP_FIRING = "HELP_FIRING",
    HELP_OUTRO = "HELP_OUTRO",
    HELP_CRUISE_BARRELS = "HELP_CRUISE_BARRELS",
    HELP_MOBILE_NO_SUPPORT = "HELP_MOBILE_NO_SUPPORT",
    SHIP_SINK = "SHIP_SINK",
    GUN_FIRE = "GUN_FIRE",
    GUN_FIRE_TAIL = "GUN_FIRE_TAIL",
    NO_GUN_FIRE = "NO_GUN_FIRE",
    LEVEL_UP_SHOUT = "LEVEL_UP_SHOUT",
    PICK_UP = "PICK_UP",
    MAIN_MENU = "MAIN_MENU",
    GAME_OVER = "GAME_OVER",
    BOSS = "BOSS",
    CHAMPION_OF_THE_SEA = "CHAMPION_OF_THE_SEA",
    MAKE_THEM_REMEMBER = "MAKE_THEM_REMEMBER",
    SHIP_HIT = "SHIP_HIT",
    GET_BACK = "GET_BACK",
}