export const TurnSpeedIconSVG = () => {
    return (
        <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3638_1401)">
                <path d="M14.5381 9.25931V6.94436H10.5865V2.52909H14.5381V0.216877L19.2365 4.73673L14.5381 9.25931ZM11.1979 6.33288H15.1495V7.82174L18.3544 4.73681L15.1495 1.65372V3.14057H11.1979V6.33288ZM4.69842 9.25931L0 4.73665L4.69842 0.216797V2.52909H8.65005V6.94436H4.69842V9.25931ZM0.881947 4.73689L4.08686 7.82182V6.33296H8.03849V3.14073H4.08678V1.6538L0.881947 4.73689Z" fill="white" />
                <path d="M0.881947 4.73689L4.08686 7.82182V6.33296H8.03849V3.14073H4.08678V1.6538L0.881947 4.73689Z" fill="white" />
                <path d="M11.1979 6.33288H15.1495V7.82174L18.3544 4.73681L15.1495 1.65372V3.14057H11.1979V6.33288Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_3638_1401">
                    <rect width="19.29" height="9.31241" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}