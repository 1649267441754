import { JSX, createEffect, createSignal, onCleanup, onMount } from 'solid-js';
import { AnalyticsController } from './game/Controllers/AnalyticsController';
import { AudioController } from './game/Controllers/AudioController';
import { LocalStorageController } from './game/Controllers/LocalStorageController';
import { ITransformPayload, NetController } from './game/Controllers/NetController';
import { Notif, NotificationType } from './game/Controllers/NotifierController';
import { SBGame } from './game/SBGame';
import { SBGameMenu } from './game/SBGameMenu';
import { SBGameLogo } from './game/commons/branding/SBGameLogo';
import { Arrows2SVG } from './game/commons/components/Arrows2SVG';
import { ArrowsSVG } from './game/commons/components/ArrowsSVG';
import { Banner300x250 } from './game/commons/components/Banner300x250';
import { ChatMessage } from './game/commons/components/ChatMessage';
import { CustomizationItem } from './game/commons/components/CustomizationItem';
import { CustomizationModalInner } from './game/commons/components/CustomizationModalInner';
import { Modal } from './game/commons/components/Modal';
import { PlayerAttributes } from './game/commons/components/PlayerAttributes';
import { SectionHeader } from './game/commons/components/SectionHeader';
import mainMenuStyles from './game/commons/css/MainMenu.module.css';
import MapStyles from './game/commons/css/Map.module.css';
import { SAIL_NAMES, SAILS_CUSTOMIZATIONS } from './game/commons/customizations/sails';
import { LocalStorageKeys } from './game/commons/enums/LocalStorageKeys';
import { EditIconSvg } from './game/commons/icons/EditIconSVG';
import { InfoIconSvg } from './game/commons/icons/InfoIconSvg';
import { auxClickResetInputs } from './game/commons/utils/auxClickResetInputs';
import { getResourceLink } from './game/commons/utils/getResourceLink';
import { CrazyGamesController, InviteLinkParams } from './game/commons/vendors/CrazyGamesController';
import { BASE_PROJECTS_URL, CAMERA_POS, EventBusNames, EXP_NEEDED_TO_LEVEL_UP, GAME_SIZE, MAP_SPECIAL_INDICATOR } from './game/constants';
import { PokiController } from './game/commons/vendors/PokiController';
import { GMSDKController } from './game/Controllers/GMSDKController';
import { EventBus } from './game/EventBus';
import { GDSDKController } from './game/Controllers/GDSDKController';

export enum PageNames {
    HOME = 'home',
    PLAY = 'play',
}
export enum GameState {
    LOADING,
    READY,
}

export interface IOpponent extends ITransformPayload {
    id: string;
    score: number;
    name: string;
    specialty?: MAP_SPECIAL_INDICATOR
}

export interface IUpgradeBonus {
    type: MAP_SPECIAL_INDICATOR,
    ratio: number;
}

export const [pos, set_pos] = createSignal({
    x: 0,
    y: 0,
});

export const [is_mobile, set_is_mobile] = createSignal((window as any).MOBILE_DEVICE);
export const ONBOARDED = is_mobile() || JSON.parse(LocalStorageController.getItem(LocalStorageKeys.ONBOARDED) ?? 'false');

export const [focused, set_focused] = createSignal(true);
export const [mute, set_mute] = createSignal(false);
export const [ping, set_ping] = createSignal(0);
export const [invite_link, set_invite_link] = createSignal('');
export const [show_ping, set_show_ping] = createSignal(false);
export const [show_help, set_show_help] = createSignal(false);
export const [show_loader, set_show_loader] = createSignal(true);
// export const [show_tutorial, set_show_tutorial] = createSignal(!is_mobile() && ONBOARDED);
// export const [should_start_game, set_should_start_game] = createSignal(!is_mobile() && ONBOARDED);
export const [show_tutorial, set_show_tutorial] = createSignal(false);
export const [should_start_game, set_should_start_game] = createSignal(false);
export const [disconnected, set_disconnected] = createSignal(false);
export const [show_new_level_celebration, set_show_new_level_celebration] = createSignal(false);
export const [video_ad_playing, set_video_ad_playing] = createSignal(false);

export const [opponents, set_opponents] = createSignal<{ [key: string]: IOpponent }>({});
export const opponents_repo: any = {};
// setInterval(() => {
//     set_opponents(opponents_repo);
// }, 1000);
export const [crates, set_crates] = createSignal<{ [key: string]: IOpponent }>({});
export const [upgrades, set_upgrades] = createSignal<IUpgradeBonus[]>([]);

// export const [current_page, set_current_page] = createSignal<PageNames>(new URLSearchParams(location.search).has(InviteLinkParams.INSTANT_JOIN) && JSON.parse(new URLSearchParams(location.search).get(InviteLinkParams.INSTANT_JOIN) || 'false') ? PageNames.PLAY : PageNames.HOME);
export const [current_page, set_current_page] = createSignal<PageNames>(ONBOARDED ? PageNames.PLAY : PageNames.HOME);
export const [username, set_username] = createSignal('GUEST');
export const [user_level, set_user_level] = createSignal(1);
export const [user_xp, set_user_xp] = createSignal(1);
export const [player_near_boss, set_player_near_boss] = createSignal(false);
export const [boss_id, set_boss_id] = createSignal<string | undefined>();
export const [boss_health, set_boss_health] = createSignal(0);

export const [health_attr, set_health_attr] = createSignal(0);
export const [damage_attr, set_damage_attr] = createSignal(0);
export const [speed_attr, set_speed_attr] = createSignal(0);
export const [turn_speed_attr, set_turn_speed_attr] = createSignal(0);
export const [range_attr, set_range_attr] = createSignal(0);
export const [reload_time_attr, set_reload_time_attr] = createSignal(0);
export const [user_score, set_user_score] = createSignal(0);

export const [chat, set_chat] = createSignal<{ user: string, content: string, important?: boolean }[]>([]);
export const [using_chat, set_using_chat] = createSignal(false);
export const [game_over, set_game_over] = createSignal(false);
export const [show_sail_customs, set_show_sail_customs] = createSignal(false);
// export const [blur_sail_customs_bg, set_blur_sail_customs_bg] = createSignal(false);
export const [notifs, set_notifs] = createSignal<Map<string, Notif>>(new Map());

export const [selected_sail, set_selected_sail] = createSignal<string | undefined>(SAIL_NAMES.NONE);
export const [temporary_selected_sail, set_temporary_selected_sail] = createSignal<string | undefined>(selected_sail());
export const [time_temporary_selected_sail, set_time_temporary_selected_sail] = createSignal(performance.now());

const App = () => {
    // GDSDKController.getInstance().preloadRewardedAd();

    window.addEventListener('resize', () => {
        const bodyStyle = getComputedStyle(document.documentElement);

        (window as any).MOBILE_DEVICE = !!bodyStyle.getPropertyValue('--is-mobile');

        set_is_mobile((window as any).MOBILE_DEVICE);
    });

    const SIZE = 9;
    const MAP_SIZE = 142;
    const HALF_MAP_SIZE = MAP_SIZE * 0.5;

    set_mute(JSON.parse(LocalStorageController.getItem(LocalStorageKeys.MUTED) || 'false'));
    set_username(LocalStorageController.getItem(LocalStorageKeys.USERNAME) || 'GUEST');
    set_selected_sail(LocalStorageController.getItem(LocalStorageKeys.SAILS) || SAIL_NAMES.NONE);

    createEffect(() => {
        if (game_over()) {
            AnalyticsController.getInstance().logScreenView('game_over');
        }
    });
    createEffect(() => {
        if (show_tutorial()) {
            AnalyticsController.getInstance().logScreenView('tutorial');
        }
    });
    createEffect(() => {
        LocalStorageController.setItem(LocalStorageKeys.MUTED, mute().toString());
    });
    createEffect(() => {
        AnalyticsController.getInstance().logScreenView(current_page());

        if (current_page() == PageNames.HOME) {
            CrazyGamesController.getInstance().hideInviteButton();
        }
    });

    onMount(() => {
        console.log('app mount');

        document.onvisibilitychange = () => {
            if (document.hidden) {
                set_focused(false);
                console.log('focus:', false);
            } else {
                set_focused(true);
                console.log('focus:', true);
            }
        };

        onCleanup(() => {
            console.log('app clean up');
        })
    });

    return (
        <div id="app">
            {/* Sails modal */}
            {
                show_sail_customs() && <Modal
                    title="Sails"
                    onOpen={() => {
                        AnalyticsController.getInstance().logSailsButtonClicked();

                        if (CrazyGamesController.getInstance()?.sdk) {
                            CrazyGamesController.getInstance().canCycleBannerDuringThisRound = false;
                        }
                    }}
                    onClose={() => {
                        if (CrazyGamesController.getInstance()?.sdk) {
                            CrazyGamesController.getInstance().canCycleBannerDuringThisRound = true;
                        }

                        set_show_sail_customs(false);

                        setTimeout(() => {
                            set_temporary_selected_sail(selected_sail());
                        }, 120);

                        // console.log('closer:', temporary_selected_sail());
                    }}
                >
                    <CustomizationModalInner
                        selected={selected_sail()}
                        onSelect={async selectedKey => {
                            if (video_ad_playing()) return;
                            // set_selected_sail(undefined);

                            !!selectedKey && AnalyticsController.getInstance().logSailsPicked(selectedKey as any);

                            if (selectedKey !== selected_sail()) {
                                const selectedSkin = SAILS_CUSTOMIZATIONS.find(skin => skin.name == selectedKey);
                                if (selectedSkin && !selectedSkin?.unlocked) {
                                    const oldMuted = mute();

                                    const adFinished = async (success?: boolean) => {
                                        selectedSkin.unlocked = success ?? true;

                                        if (oldMuted) {
                                            AudioController.getInstance().mute();
                                        } else {
                                            AudioController.getInstance().unmute();
                                        }
                                    }

                                    // Show rewarded ad
                                    set_video_ad_playing(true);
                                    // try {
                                    //     await CrazyGamesController.getInstance().requestRewardedVidAd({
                                    //         adStarted() {
                                    //             AudioController.getInstance().mute();
                                    //         },
                                    //         adFinished,
                                    //         adError: adFinished,
                                    //     });
                                    // } catch (err) {
                                    //     console.error(err);
                                    // }

                                    const modal = document.getElementById(game_over() ? 'game-over' : 'modal-Sails');

                                    if (PokiController.getInstance().sdk) {
                                        try {
                                            await PokiController.getInstance().requestRewardedVidAd({
                                                adStarted() {
                                                    AudioController.getInstance().mute();
                                                },
                                                adFinished,
                                                adError: adFinished,
                                            });
                                        } catch (err) {
                                            console.error(err);
                                        }
                                    } else if (window.IS_GM) {

                                        if (modal) {
                                            modal.style.display = 'none';
                                        }

                                        AudioController.getInstance().mute();
                                        GMSDKController.getInstance().showBanner();
                                        await new Promise<void>((res, rej) => {
                                            EventBus.once(EventBusNames.AD_DONE, () => {
                                                adFinished();

                                                if (modal) {
                                                    modal.style.display = '';
                                                }

                                                res();
                                            });
                                        })
                                    } else if (window.IS_GD) {
                                        if (modal) {
                                            modal.style.display = 'none';
                                        }

                                        AudioController.getInstance().mute();

                                        await new Promise<void>(res => {
                                            GDSDKController.getInstance().preloadRewardedAd().then(() => {
                                                res(GDSDKController.getInstance().showRewardedAd().then(() => {
                                                    adFinished();

                                                    if (modal) {
                                                        modal.style.display = '';
                                                    }
                                                }));
                                            })
                                                .catch(() => {
                                                    res();
                                                });
                                        });
                                    } else {
                                        adFinished();
                                    }
                                    set_video_ad_playing(false);

                                    // try {
                                    //     await CrazyGamesController.getInstance().requestResponsiveBannerAd(game_over() ? 'game-over-banner' : 'menu-banner');
                                    // } catch (err) {
                                    //     console.error(err);
                                    // }
                                }
                            }

                            set_selected_sail(selectedKey);
                            console.log('sail selected:', selected_sail(), selectedKey);
                            set_show_sail_customs(false);

                            LocalStorageController.setItem(LocalStorageKeys.SAILS, selectedKey || '');

                            set_temporary_selected_sail(selected_sail());
                        }}
                        onHover={(selectedKey, isHovered) => {
                            if (isHovered) {
                                set_temporary_selected_sail(selectedKey);
                                set_time_temporary_selected_sail(performance.now());
                            } else {
                                const curTime = performance.now();
                                set_time_temporary_selected_sail(curTime);
                                setTimeout(() => {
                                    if (time_temporary_selected_sail() == curTime) {
                                        set_temporary_selected_sail(undefined);
                                    }
                                }, 500);
                            }
                        }}
                        list={SAILS_CUSTOMIZATIONS}
                    />
                </Modal>
            }

            {/* Tutorial */}
            {
                !is_mobile() && show_tutorial() && (<div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        "z-index": 10000,
                        background: 'rgba(10, 10, 10, 0.52)',
                        width: '100vw',
                        height: '100vh',
                        cursor: 'pointer',
                        display: 'flex',
                        "flex-direction": 'column',
                        "justify-content": 'center',
                        gap: '16px',
                    }}
                    onClick={() => {
                        set_show_tutorial(false);

                        if (should_start_game()) {
                            set_should_start_game(false);
                            // set_show_loader(true);
                            set_current_page(PageNames.PLAY);
                        }
                    }}
                    id="tutorial"
                >
                    <div style={{
                        display: 'flex',
                        "justify-content": 'center',
                        "align-items": 'center',
                    }}
                    >
                        <div style={{
                            display: 'grid',
                            "grid-template-columns": '1fr 1fr 1fr',
                            // "justify-content": 'space-between',
                            gap: '12px',
                        }}>
                            {[
                                {
                                    title: 'Movement',
                                    desc: <div><strong>Move</strong> with <strong>UP</strong>, <strong>LEFT</strong>, <strong>RIGHT</strong> arrows</div>,
                                    media: 'movement_screen_shot.png'
                                },
                                {
                                    title: 'Combat',
                                    desc: <div><strong>Fire</strong> with <strong>W</strong>, <strong>A</strong>, <strong>S</strong>, and <strong>D</strong></div>,
                                    media: 'combat_screen_shot.png'
                                },
                                {
                                    title: 'Objectives',
                                    desc: <div>Climb <strong>leaderboard</strong> by getting <strong>kills</strong> and collecting crates. <strong>Crates</strong> also <strong>heal</strong> you</div>,
                                    media: 'objectives_screen_shot.png'
                                },
                            ].map(({ title, desc, media }) => {
                                return <div style={{
                                    display: 'flex',
                                    "flex-direction": 'column',
                                    gap: '1rem',
                                    padding: '25px 14px',
                                    background: 'var(--white)',
                                    color: 'var(--black)',
                                    "justify-content": 'space-between',
                                    "align-items": 'center',
                                    // height: '100%',
                                    // width: '33.33333%',
                                    width: '100%',
                                    "min-width": 0,
                                    "box-sizing": 'border-box',
                                }}>
                                    <div style={{
                                        "font-size": '24px',
                                        "font-weight": '700',
                                        color: 'var(--white)',
                                        background: 'var(--black)',
                                        width: '100%',
                                        "text-align": 'center',
                                        padding: '4px 0',
                                    }}>{title}</div>
                                    <div style={{
                                        "text-align": 'center',
                                    }}>{desc}</div>
                                    <div style={{
                                        width: '100%',
                                        "aspect-ratio": '367 / 225',
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: 'auto',
                                        }} src={`assets/img/${media}`} alt="" />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        "justify-content": 'center',
                    }}>
                        <div style={{
                            color: 'var(--black)',
                            background: 'var(--white)',
                            display: 'inline',
                            padding: '7px',
                            "font-size": '25px',
                            "font-weight": '600',
                        }}>
                            Continue
                        </div>
                    </div>
                </div>)
            }

            {
                current_page() == PageNames.HOME && (
                    <div style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                    }}>
                        <SBGameMenu />
                        <div
                            style={{
                                display: 'flex',
                                "align-items": 'center',
                                "justify-content": 'center',
                                "flex-direction": 'column',
                                gap: is_mobile() ? '0px' : '14px',
                                width: '100%',
                                height: '100%',
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                transition: 'transform .75s, opacity .75s',
                                opacity: 0,
                                // "will-change": 'transform, opacity'
                                // display: 'none',
                            }}
                            id="main-menu"
                        >
                            <SBGameLogo style={{
                                position: is_mobile() ? '' : 'fixed',
                                // top: window.innerWidth < 1000 ? '3.4vh' : '10vh',
                                top: '3.4vh',
                            }} />

                            <div style={{
                                width: '43vw',
                                height: '52vh',
                                display: 'grid',
                                // "align-items": 'end',
                                // "justify-content": 'space-between',
                                // "text-align": 'right',
                                "grid-template-columns": '1fr 2fr 1fr',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    "flex-direction": 'column',
                                    "align-items": 'end',
                                    "justify-content": 'start',
                                    "min-width": '100%',
                                }}>
                                    <SectionHeader>Name</SectionHeader>
                                    <div
                                        style={{
                                            display: 'flex',
                                            "justify-content": 'space-between',
                                            "align-items": 'center',
                                            background: 'var(--black)',
                                            "padding-right": '8px',
                                        }}
                                    >
                                        <input
                                            type="text"
                                            id="username"
                                            // style={{
                                            //     display: 'flex',
                                            //     "justify-content": 'center',
                                            //     "align-items": 'center',
                                            //     width: '100%',
                                            //     height: '100%',
                                            //     "box-sizing": 'border-box',
                                            //     background: 'var(--black)',
                                            //     "padding-right": '4px',
                                            // }}
                                            value={username()}
                                            onchange={(ev) => {
                                                LocalStorageController.setItem(LocalStorageKeys.USERNAME, ev.target.value || 'GUEST');
                                                set_username(ev.target.value || 'GUEST');
                                            }}
                                            onblur={_ => {
                                                if (username() == '') {
                                                    console.log('blank')
                                                } else {
                                                    console.log('full:', username())
                                                }
                                            }}
                                            onfocus={_ => {
                                                AnalyticsController.getInstance().logNameInputFocused();
                                            }}
                                            placeholder="Input your username"
                                            class={[mainMenuStyles.input, ''].join(' ')}
                                        />
                                        <EditIconSvg />
                                    </div>
                                    <Banner300x250 />
                                </div>
                                <div></div>
                                <div style={{
                                    // visibility: 'hidden',
                                    "user-select": 'none',
                                    "min-width": '100%',
                                }}>
                                    <SectionHeader>
                                        Customizations
                                    </SectionHeader>

                                    <CustomizationItem
                                        name="sail"
                                        current={SAILS_CUSTOMIZATIONS
                                            .find(
                                                sail => {
                                                    console.log('res:', sail.name == selected_sail());


                                                    return sail.name == selected_sail();
                                                    // @ts-ignore
                                                })?.icon?.cloneNode(true)
                                        } onClick={() => {
                                            set_show_sail_customs(true);
                                        }}
                                    />
                                </div>
                                {/* <div style={{
                            padding: '4px',
                            background: 'var(--white)',
                            color: 'var(--black)',
                            "font-weight": 'black',
                            "text-align": 'center',
                            "font-size": '14px',
                            cursor: 'pointer',
                        }}
                            onClick={() => {
                                set_current_page(PageNames.PLAY);
                            }}>PLAY</div> */}
                            </div>

                            <footer style={{
                                position: 'fixed',
                                bottom: '10px',
                            }}>&copy; <span>KIGA GAMES</span> {(new Date()).getFullYear()}</footer>
                            {/* // }} href="https://kitanga.dev" target="_blank">KIGA GAMES</a> {(new Date()).getFullYear()}</footer> */}
                        </div>
                        {/* <div
                            style={{
                                // 
                            }}
                            class="pulse"
                            id="skip-prompt"
                            onClick={() =>  }
                            >
                            Click or SPACEBAR to skip
                        </div> */}
                    </div>
                )
            }

            {
                current_page() == PageNames.PLAY && (<>
                    <SBGame />

                    {/* In-game notifications */}
                    <div
                        style={{
                            position: 'fixed',
                            "z-index": '1000',
                            left: '0',
                            top: '10px',
                            // top: 'calc(50% - 50vh)',
                            // height: '100%',
                            display: 'flex',
                            "flex-direction": 'column',
                            "justify-content": 'center',
                            "align-items": 'center',
                            width: '100vw',
                        }}
                    >
                        {Array.from(notifs()).map(([_, notif]) => {
                            return <div
                                style={{
                                    display: 'flex',
                                    "justify-content": 'center',
                                    "align-items": 'center',
                                    gap: '4px',
                                    "font-size": '25px',
                                    "font-weight": '500',
                                    "font-family": 'Arial',
                                    padding: '8px 0',
                                    "padding-left": '16px',
                                    background: 'linear-gradient(90deg, rgba(20, 20, 20, 0) 0%, #141414 40%, #141414 60%, rgba(20, 20, 20, 0) 100%)',
                                    "min-width": '25vw',
                                }}>
                                {notif.type === NotificationType.INFO && (<>
                                    <InfoIconSvg /> {notif.content}
                                </>)}
                                {notif.type === NotificationType.UNLOCK && (<>
                                    <span style={{
                                        "aspect-ratio": 1,
                                        height: '43px',
                                    }}>
                                        <img style={{
                                            width: '100%',
                                            height: '100%',
                                        }} src={`${BASE_PROJECTS_URL}/img/${notif.img}`} alt="sail skin for ships in sunbaked" />
                                    </span> Unlocked {notif.content}
                                </>)}
                            </div>
                        })}
                    </div>

                    {/* Leaderboard */}
                    <div
                        style={{
                            position: 'fixed',
                            right: is_mobile() ?
                                ((window as any).IS_POKI ? '12px' : '5px') : '5px',
                            top: '10px',
                            "min-width": '10vw',
                        }}
                    >
                        {/* TODO: (optionally) Header */}
                        {/* C */}

                        <div
                            style={{
                                width: '100%',
                                display: 'grid',
                                gap: '10px',
                            }}
                        >
                            {/* @ts-ignore */}
                            {(opponents() || (user_score() > -1)) && (() => {
                                let leaderboard: any[] = [];
                                const score = user_score();
                                const name = username();

                                let playerID = '';
                                let playerPos = 0;
                                let playerScoreData = { score, name, id: playerID };

                                leaderboard.push(playerScoreData);

                                if (NetController.instance && NetController?.instance?.id) {
                                    const net = NetController.getInstance()!;

                                    playerID = net?.id;

                                    const opponentsList = opponents();

                                    const playerScoreData = { score, name, id: playerID };

                                    const sortedOpponentList = [...Object.keys(opponentsList)
                                        .map(key => ({ ...opponentsList[key], id: key })), playerScoreData]
                                        .sort((a, b) => b?.score - a?.score) || [];

                                    playerPos = (sortedOpponentList || [])?.findLastIndex?.(entity => entity.id == playerID);

                                    const size = is_mobile() ? 6 : 8;

                                    if (playerPos > size) {
                                        // Our player is beyond the list (most likely)
                                        leaderboard = [...(sortedOpponentList || [])?.slice?.(0, size), playerScoreData];
                                    } else {
                                        // Player is within the list
                                        leaderboard = (sortedOpponentList || [])?.slice?.(0, 9);
                                    }

                                    const bossID = leaderboard[0].id;

                                    // if (bossID !== playerID) {
                                    set_boss_id(bossID);
                                    // } else {
                                    // set_boss_id(undefined);
                                    // }
                                }

                                return leaderboard.map((entity, ix) => {
                                    const isCurrentPlayer = entity.id == playerID;

                                    let styles = {
                                        display: 'flex',
                                        "align-items": 'center',
                                        "justify-content": 'space-between',
                                        width: '100%',
                                        color: isCurrentPlayer ? 'var(--black)' : 'var(--white)',
                                        background: isCurrentPlayer ? 'var(--white)' : 'rgba(20, 20, 20, 0.34)',
                                        padding: '4px',
                                        "border-radius": '2px 0 0 2px',
                                        "font-size": '12px',
                                    } as JSX.CSSProperties;

                                    if (ix == 0) {
                                        styles.color = 'var(--black)';
                                        styles.background = 'var(--yellow)';
                                        styles["font-weight"] = 700;
                                    }

                                    return <div
                                        style={styles}
                                    >
                                        <div
                                            style={{
                                                "text-overflow": 'ellipsis',
                                                overflow: 'hidden',
                                                "white-space": 'nowrap',
                                                "width": is_mobile() ? '68px' : '120px',
                                            }}>{(isCurrentPlayer ? playerPos : ix) + 1}.{entity.name}</div>
                                        <div>{entity.score ?? 0}</div>
                                    </div>
                                });
                            })}
                        </div>
                    </div>

                    {/* Level Up celebration */}
                    {<div style={{
                        // NB: the 100% width is used in conjunction with display:flex, so that we can center the banner horizontally
                        width: '100%',
                        position: 'fixed',
                        top: '7vh',
                        left: '0',
                        display: 'flex',
                        "justify-content": 'center',
                        "content-visibility": 'visible',
                        opacity: show_new_level_celebration() ? 1 : 0,
                        transition: 'opacity .25s',
                    }}
                        onAuxClick={auxClickResetInputs}
                    >
                        <div style={{
                            width: '25vw',
                            display: 'grid',
                            "grid-template-rows": '1fr auto',
                            "pointer-events": 'none',
                        }}>
                            {/* New Level */}
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    "min-width": '0',
                                    "min-height": '0',
                                    position: 'relative',
                                }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        // display: 'flex',
                                        // "justify-content": 'center',
                                        // "align-items": 'center',
                                        // "flex-direction": 'column',
                                        "z-index": -1,
                                    }}
                                >
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        "overflow-y": 'hidden',
                                        "overflow-x": 'unset',
                                    }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                "justify-content": 'center',
                                                position: 'absolute',
                                                top: '100%',
                                                animation: 'move-arrow-up 1.4s linear infinite',
                                                "animation-delay": '0.7s',
                                            }}
                                        >
                                            <ArrowsSVG />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                "justify-content": 'center',
                                                position: 'absolute',
                                                top: '100%',
                                                animation: 'move-arrow-up 1.4s linear infinite',
                                                "animation-delay": '1.4s',
                                            }}
                                        >
                                            <Arrows2SVG />
                                        </div>
                                        {/* <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                "justify-content": 'center',
                                                position: 'absolute',
                                                top: '100%',
                                                animation: 'move-arrow-up 1s linear infinite',
                                                "animation-delay": '1.5s',
                                            }}
                                        >
                                            <ArrowsSVG />
                                        </div> */}
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    "justify-content": 'center',
                                }} class="level-up-ships">
                                    <div style={{
                                        width: '50%',
                                        display: 'flex',
                                    }}>
                                        <img
                                            style={{ width: '100%' }}
                                            src={getResourceLink('assets/img/level-up-banner-ship.png')}
                                            alt="ship"
                                        />
                                    </div>
                                    <div style={{
                                        width: '50%',
                                        display: 'flex',
                                    }}>
                                        <img
                                            style={{ width: '100%', height: 'auto', transform: 'scaleX(-1)', }}
                                            src={getResourceLink('assets/img/level-up-banner-ship.png')}
                                            alt="ship"
                                        />
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    "justify-content": 'center',
                                    "align-items": 'end',
                                    "padding-bottom": '4px',
                                    "box-sizing": 'border-box',
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    width: '100%',
                                    height: '100%',
                                }}>
                                    <div style={{
                                        border: 'black',
                                        background: 'var(--black)',
                                        color: 'var(--white)',
                                        "border-radius": '50%',
                                        // padding: '10px',
                                        width: (window.innerWidth * 140 / 1865) + 'px',
                                        // height: '34px',
                                        "aspect-ratio": 1,
                                        "justify-content": 'center',
                                        "align-items": 'center',
                                        display: 'flex',
                                        "font-size": (window.innerWidth * 86 / 1865) + 'px',
                                        position: 'relative'
                                    }}>
                                        <span id="new-level" style="opacity: 0"></span>
                                    </div>
                                </div>
                            </div>

                            {/* Level Text */}
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    "justify-content": 'center',
                                    "align-items": 'center',
                                    padding: (window.innerWidth * 8 / 1865) + 'px 0',
                                    "font-size": (window.innerWidth * 25 / 1865) + 'px',
                                    "font-weight": 'bolder',
                                    "text-transform": 'uppercase',
                                    color: 'var(--black)',
                                    background: 'linear-gradient(90deg, rgba(219, 165, 7, 0) 0%, #DBA507 52%, rgba(219, 165, 7, 0) 100%)',
                                }}
                            >
                                {user_level() == EXP_NEEDED_TO_LEVEL_UP.length + 1 ? 'MAX Level' : 'Level Up!'}
                            </div>
                        </div>
                    </div>}

                    {/* Chat */}
                    <div
                        style={{
                            position: 'fixed',
                            left: 0,
                            bottom: is_mobile() && !(window as any).PORTRAIT_ORIENTATION ? '' : 0,
                            top: is_mobile() && !(window as any).PORTRAIT_ORIENTATION ? 0 : '',
                            width: is_mobile() ? '34vw' : '18vw',
                            // width: '100%',
                            "user-select": 'initial'
                        }}
                        onclick={auxClickResetInputs}
                        onAuxClick={auxClickResetInputs}
                    >
                        <div style={{
                            height: is_mobile() ? '' : '100vh',
                            display: 'flex',
                            "flex-direction": 'column',
                            "justify-content": 'space-between',
                        }}>
                            {/* Attributes */}
                            <PlayerAttributes />
                            {/* Messages box */}
                            {!is_mobile() && <div>
                                <div
                                    id="messages-list-sunbaked"
                                    style={{
                                        width: '100%',
                                        "height": '25vh',
                                        background: 'rgba(10, 10, 10, 0.52)',
                                        color: 'var(--white)',
                                        padding: '4px 4px 0 4px',
                                        "box-sizing": 'border-box',
                                        'overflow-y': 'auto',
                                        "overflow-x": 'hidden',
                                        "margin-top": '8px',
                                    }}
                                >
                                    {chat().map((message, ix, { length }) =>
                                        <ChatMessage message={message} id={performance.now() + Math.random()} autofocus={ix == length - 1 ? true : false} />
                                    )}
                                </div>

                                {/* Message input */}
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'grid',
                                        "grid-template-columns": '1fr auto',
                                    }}
                                >
                                    <input
                                        style={{
                                            height: '25px',
                                            outline: 'none',
                                            "box-sizing": 'border-box',
                                            "min-width": '0',
                                        }}
                                        type="text"
                                        id="chat-input"
                                        placeholder='Chat here, be nice 😄...'
                                        onkeyup={ev => {
                                        }}
                                        onFocus={() => {
                                            console.log('onFocus');
                                            set_using_chat(true);
                                        }}
                                        onBlur={() => {
                                            console.log('onBlur');
                                            set_using_chat(false);
                                        }}
                                    />
                                    <button id="chat-send-btn" style={{
                                        background: 'var(--black)',
                                        color: 'var(--white)',
                                        border: 'none',
                                        height: '25px',
                                        cursor: 'pointer',
                                        "min-width": '0',
                                    }} type="button" onClick={() => {
                                        const input = document.getElementById('chat-input') as HTMLInputElement;
                                        if (input) {
                                            if (input.value) {
                                                const net = NetController.getInstance();
                                                net?.sendMessage('CHAT', {
                                                    c: input.value,
                                                });
                                                input.value = '';
                                                input.blur();
                                            }
                                        }
                                    }}>Send</button>
                                </div>
                            </div>}
                        </div>
                    </div>
                    {/* Help modal */}
                    {show_help() && <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,

                        width: '100vw',
                        height: '100vh',

                        display: 'flex',
                        "align-items": 'center',
                        "justify-content": 'center',
                        "flex-direction": 'column',

                        cursor: 'pointer',
                        background: '#00000070',
                    }} onClick={() => {
                        set_show_help(false);
                    }}
                        onAuxClick={auxClickResetInputs}
                    >
                        <div>
                            <div style={{
                                color: 'white',
                                width: '100%',
                                "text-align": 'right',
                            }}>x</div>
                            <div style={{
                                padding: '10px 10px',
                                background: 'white',
                                color: 'black',
                            }}>
                                {[
                                    {
                                        title: 'Movement',
                                        description: 'UP, move in direction you facing',
                                    },
                                    {
                                        title: 'Turning',
                                        description: 'LEFT/RIGHT to rotate anti-clockwise/clockwise respectively',
                                    },
                                    {
                                        title: 'Shooting',
                                        description: <span>
                                            <strong>W/A/S/D</strong> to fire left/right/backwards/forwards respectively.
                                        </span>,
                                    },
                                    // {
                                    //     title: 'Zoom',
                                    //     description: 'Use scroll wheel or pinch on mobile',
                                    // },
                                    {
                                        title: 'Turn on pixelated mode',
                                        description: 'Use P key',
                                    },
                                    {
                                        title: 'WARNING',
                                        description: "This isn't mobile friendly yet",
                                    },
                                ].map(({ title, description }) => <div style={{
                                    "margin-bottom": '10px'
                                }}>
                                    <strong>{title}</strong>: {description}</div>)
                                }
                            </div>
                        </div>
                    </div>}

                    {/* Loader modal */}
                    {show_loader() && <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        "z-index": 1000,

                        width: '100vw',
                        height: '100vh',

                        display: 'flex',
                        "align-items": 'center',
                        "justify-content": 'center',
                        "flex-direction": 'column',

                        background: '#00000070',
                        "backdrop-filter": 'blur(7px)',
                    }}>
                        <div class="loader"></div>
                    </div>}

                    {/* Alerts that show up to show ping, lack of mobile device support, etc. */}
                    <div style={{
                        position: 'fixed',
                        top: '10px',
                        left: '0',
                        "user-select": 'none',
                    }}>
                        {/* <div id="mobile-prompt" style={{
                            padding: '3px 4px',
                            background: 'black',
                            color: 'white',
                            "margin-bottom": '4px',
                        }}>
                            Won't work on phones for now, sorry 😔
                        </div> */}
                        {show_ping() && (<div style={{
                            padding: '3px 4px',
                            background: 'black',
                            color: 'white',
                            "margin-bottom": '4px',
                        }}>{Math.round(ping())}ms</div>)}
                        {disconnected() && <div style={{
                            padding: '3px 4px',
                            background: 'black',
                            color: 'white',
                        }}>
                            You have been disconnected from server, please reload;
                        </div>}
                    </div>

                    {/* Boss health bar */}
                    {player_near_boss() && boss_id() && boss_id() !== NetController.instance?.id && boss_health() && (() => {
                        const boss = NetController.instance?.entities.get(boss_id() as string);

                        if (boss) {
                            return <div style={{
                                position: 'fixed',
                                top: '12px',
                                left: 0,
                                width: '100vw',
                                display: 'flex',
                                gap: '8px',
                                "flex-direction": 'column',
                                padding: '0 25vw',
                                "box-sizing": 'border-box',
                                "text-align": 'center',
                            }}>
                                <div
                                    style={{
                                        "text-shadow": '1px 1px var(--black)',
                                    }}
                                >{boss.user}</div>

                                {/* Boss health bar */}
                                <div
                                    style={{
                                        width: '100%',
                                        height: '10px',
                                        background: 'rgba(255, 255, 255, 0.34)',
                                        display: 'flex',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: `${boss_health() * 100}%`,
                                            height: `100%`,
                                            background: '#5f0000',
                                        }}
                                    ></div>
                                </div>
                                <div
                                    style={{
                                        "text-align": 'center',
                                    }}
                                >
                                    <div style={{
                                        display: 'inline',
                                        background: 'var(--black)',
                                        color: 'var(--white)',
                                        padding: '1px 3px',
                                    }}>
                                        Top Player nearby. Boss Music Starts to play!
                                    </div>
                                </div>
                            </div>;
                        }
                    })}

                    {/* Help button and minimap */}
                    <div style={{
                        position: 'fixed',
                        bottom: '0',
                        right: '0',
                        "text-align": 'right',
                        display: 'flex',
                        "align-items": 'end',
                        "justify-content": 'start',
                        "flex-direction": 'column',
                        gap: '8px',
                    }}>
                        <div
                            style={{
                                display: 'flex',
                                "justify-content": 'center',
                                "align-items": 'center',
                                gap: '4px',
                            }}
                        >
                            {[
                                {
                                    icon: <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        "justify-content": 'center',
                                        "align-items": 'center',
                                    }}>
                                        {mute() ? <div style={{
                                            width: '3px',
                                            height: '100%',
                                            position: 'absolute',
                                            background: 'var(--black)',
                                            transform: 'rotate(45deg)',
                                        }}></div> : <></>}
                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3671_865)">
                                                <path d="M4.625 10.875C4.625 11.5654 3.78553 12.125 2.75 12.125C1.71447 12.125 0.875 11.5654 0.875 10.875C0.875 10.1846 1.71447 9.625 2.75 9.625C3.78553 9.625 4.625 10.1846 4.625 10.875ZM4.625 10.875V2.125L12.125 0.875V9.625M12.125 9.625C12.125 10.3154 11.2856 10.875 10.25 10.875C9.21444 10.875 8.375 10.3154 8.375 9.625C8.375 8.93463 9.21444 8.375 10.25 8.375C11.2856 8.375 12.125 8.93463 12.125 9.625ZM4.625 4.625L12.125 3.375" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3671_865">
                                                    <rect width="13" height="13" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>,
                                    onClick: () => {
                                        if (!mute()) {
                                            AudioController.getInstance().mute();
                                        } else {
                                            AudioController.getInstance().unmute();
                                        }
                                    },
                                },
                                {
                                    icon: '?',
                                    onClick: () => {
                                        set_show_tutorial(prev => !prev);
                                    },
                                },
                            ].map(({ icon, onClick }) => {
                                return (<div
                                    style={{
                                        width: '25px',
                                        height: '25px',
                                        background: 'white',
                                        color: 'black',
                                        display: 'flex',
                                        "align-items": 'center',
                                        "justify-content": 'center',
                                        "border-radius": '50%',
                                        cursor: 'pointer',
                                        "user-select": 'none',
                                    }}
                                    onClick={onClick}
                                >{icon}</div>)
                            })}

                        </div>
                        <div
                            style={{
                                display: 'flex',
                                "align-items": 'end',
                                gap: '4px',
                            }}
                        >
                            {!is_mobile() && <div>
                                {`x: ${Math.round(pos().x * GAME_SIZE)}, y: ${Math.round(pos().y * GAME_SIZE)}`}
                            </div>}
                            <div style={{
                                width: MAP_SIZE + 'px',
                                height: MAP_SIZE + 'px',
                                background: '#F1ECDA',
                            }}>
                                <div style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%',
                                }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            "grid-template-rows": 'repeat(9, 1fr)',
                                            "grid-template-columns": 'repeat(9, 1fr)',
                                            position: 'absolute',
                                            "z-index": '20',
                                            width: '100%',
                                            height: '100%',
                                            opacity: '0.43',
                                        }}>
                                        {new Array(9 * 9).fill(0).map((_, ix) => {
                                            const x = ix % 9;
                                            const y = (ix - x) / 9;

                                            return (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        "justify-content": 'center',
                                                        "align-items": 'center',
                                                        "min-width": '0',
                                                        "min-height": '0',
                                                        "border-right": '1px dashed var(--attr-bg)',
                                                        "border-bottom": '1px dashed var(--attr-bg)',
                                                        "font-size": '10px',
                                                        color: 'var(--attr-bg)',
                                                    }}
                                                >
                                                    {String.fromCharCode(65 + x)}{y + 1}
                                                    {/* {x}:{y} */}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* Treasure crate/chest map indicator */}
                                    {Object.keys(crates()).map(key => {
                                        const crate = crates()[key];

                                        if (crate.specialty! > -1) {
                                            // console.log('spec:', crate, crate.specialty);

                                            return <div id="position" style={{
                                                width: '25px',
                                                height: '25px',
                                                overflow: 'visible',
                                                position: 'absolute',
                                                // background: 'blue',
                                                "z-index": 2,
                                                transform: `translate(${crate.x * MAP_SIZE + HALF_MAP_SIZE - 12.5}px, ${crate.y * MAP_SIZE + HALF_MAP_SIZE - 12.5}px)`,
                                                display: 'flex',
                                                "justify-content": 'center',
                                                "align-items": 'center',
                                                "background-blend-mode": 'exclusion',
                                                // opacity: 0.7,
                                                "--main-colour": '#555555',
                                            }} class={MapStyles.specialty}>
                                                <svg width="25" height="25" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.898259 8.88299L9.38354 0.39771L51.1028 42.117L42.6176 50.6023L0.898259 8.88299Z" fill="var(--grey)" />
                                                    <path d="M9.38354 50.6023L0.898259 42.117L42.6176 0.39771L51.1028 8.88299L9.38354 50.6023Z" fill="var(--grey)" />
                                                </svg>
                                            </div>
                                        } else {
                                            return <></>
                                        }
                                    })}
                                    {/* Opponents map indicator */}
                                    {Object.keys(opponents()).map(key => opponents()[key]).map(opponent => {
                                        //#region Opponent and next player dots on map
                                        return <div id="position" style={{
                                            width: `${SIZE}px`,
                                            height: `${SIZE}px`,
                                            "border-radius": '50px',
                                            position: 'absolute',
                                            background: 'var(--yellow)',
                                            transform: `translate(${opponent.x * MAP_SIZE + HALF_MAP_SIZE - (SIZE * 0.5)}px, ${opponent.y * MAP_SIZE + HALF_MAP_SIZE - (SIZE * 0.5)}px)`,
                                            "z-index": 25,
                                            opacity: 0.86,
                                            padding: '1px',
                                            border: '1px solid var(--black)'
                                        }}></div>
                                    })}
                                    {/* Player map indicator */}
                                    <div id="position" style={{
                                        width: '12px',
                                        height: '12px',
                                        position: 'absolute',
                                        // left: `calc(50% - 1.5px)`,
                                        // top: `calc(50% - 1.5px)`,
                                        border: '1px solid var(--black)',
                                        background: 'var(--lightblue)',
                                        transform: `translate(${pos().x * MAP_SIZE + HALF_MAP_SIZE - 6}px, ${pos().y * MAP_SIZE + HALF_MAP_SIZE - 6}px)`,
                                        "z-index": 34,
                                    }}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Game Over modal */}
                    {game_over() && <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,

                            width: '100vw',
                            height: '100vh',

                            display: 'flex',
                            "align-items": 'center',
                            "justify-content": 'center',
                            "flex-direction": 'column',

                            "z-index": 9999,
                        }}
                        id='game-over'
                    >
                        {/* Closer */}
                        <div
                            style={{
                                background: '#00000070',
                                "backdrop-filter": 'grayscale(0.34)',
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                const respawnBtn = document.getElementById('respawn-btn');

                                if (respawnBtn && !respawnBtn.classList.contains('disabled')) {
                                    respawnBtn.click();
                                }

                                console.log('game over closer clicked');
                            }}
                        ></div>
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            display: 'flex',
                            "align-items": 'center',
                            "justify-content": 'center',
                            "flex-direction": 'column',
                            width: '100%',
                            height: '100%',
                            "pointer-events": 'none',
                        }}>
                            <div
                                style={{
                                    "pointer-events": 'all',
                                }}
                            >
                                {/* <div style={{
                                    color: 'white',
                                    width: '100%',
                                    "text-align": 'right',
                                    cursor: 'pointer',
                                }}>x</div> */}
                                <div
                                    style={{
                                        display: 'grid',
                                        // "grid-template": '5fr 2fr',
                                        gap: '8px',
                                        "box-sizing": 'border-box',
                                        padding: '0px 8px'
                                    }}
                                    id="game-over-inner"
                                    class={PokiController.getInstance()?.sdk ? 'poki' : ''}
                                >
                                    <div style={{
                                        padding: '3.4vh 10vw',
                                        // "aspect-ratio": '1',
                                        background: 'var(--white)',
                                        color: 'var(--black)',
                                        "text-align": 'center',
                                    }}>
                                        <h2 style={{
                                            "margin-top": '0px',
                                        }}>You died. Go. Again! 🫡</h2>

                                        <div style={{
                                            display: 'grid',
                                            "grid-template-rows": PokiController.getInstance().sdk ? '1fr 1fr' : '1fr',
                                            gap: '8px',
                                        }}>
                                            <div
                                                // You find the class definition in index.html, sorry in advance
                                                class="respawn-btn"
                                                id="respawn-btn"
                                                onClick={async () => {
                                                    const oldMuted = mute();

                                                    const adFinished = () => {
                                                        if (oldMuted) {
                                                            AudioController.getInstance().mute();
                                                        } else {
                                                            AudioController.getInstance().unmute();
                                                        }
                                                    };

                                                    if (window.IS_GM) {
                                                        // Show ad

                                                        AudioController.getInstance().mute();
                                                        GMSDKController.getInstance().showBanner();

                                                        await new Promise<void>((res, rej) => {
                                                            EventBus.once(EventBusNames.AD_DONE, () => {
                                                                res();
                                                            });
                                                        });
                                                    }
                                                    if (window.IS_GD) {
                                                        // Show ad
                                                        AudioController.getInstance().mute();
                                                        await GDSDKController.getInstance().showAd();

                                                        adFinished();
                                                    }

                                                    const net = NetController.getInstance();

                                                    if (net && net.socket.readyState == net.socket.OPEN) {

                                                        net.player.lastInput = performance.now();
                                                        net.player.reset({});

                                                        CrazyGamesController.getInstance().clearAllBannerAds();

                                                        net.sendMessage('RESPAWN', {
                                                            u: username(),
                                                            sl: selected_sail(),
                                                            new: false,
                                                        });
                                                        set_show_loader(true);
                                                        set_game_over(false);
                                                        AudioController.getInstance().stopGameOverMusic();
                                                    } else {
                                                        const search = new URLSearchParams(location.search);

                                                        search.set('instantJoin', 'true');
                                                        // search.set('play', 'true');

                                                        console.log('closed respawn:', search, search.toString());

                                                        location.search = search.toString()
                                                    }
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    "justify-content": 'center',
                                                    "align-items": 'center',
                                                }}
                                            >
                                                RESPAWN
                                            </div>
                                            <div style={{
                                                display: 'grid',
                                                "grid-template-columns": '1fr 1fr',
                                                gap: '8px',
                                            }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        "justify-content": 'center',
                                                        "align-items": 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        "box-sizing": 'border-box',
                                                        background: 'var(--black)',
                                                    }}
                                                    class="respawn-home-btn"
                                                    onClick={() => {
                                                        // set_game_over(false);
                                                        // CrazyGamesController.getInstance().clearAllBannerAds();
                                                        // AudioController.getInstance().stopGameOverMusic();
                                                        // const div = document.getElementById('game-over');
                                                        // if (div) {
                                                        //     div.style.opacity = '1';
                                                        // }
                                                        // set_current_page(PageNames.HOME);
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        id="username"
                                                        value={username()}
                                                        onchange={(ev) => {
                                                            LocalStorageController.setItem(LocalStorageKeys.USERNAME, ev.target.value || 'GUEST');
                                                            set_username(ev.target.value || 'GUEST');
                                                        }}
                                                        onblur={_ => {
                                                            if (username() == '') {
                                                                console.log('blank')
                                                            } else {
                                                                console.log('full:', username())
                                                            }
                                                        }}
                                                        placeholder="Input your username"
                                                        class={[mainMenuStyles.input, ''].join(' ')}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    />
                                                    <EditIconSvg />
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        "justify-content": 'center',
                                                        "align-items": 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        "box-sizing": 'border-box',
                                                    }}
                                                    class="respawn-home-btn"
                                                    onClick={() => {
                                                        // !blur_sail_customs_bg() && set_blur_sail_customs_bg(true);
                                                        set_show_sail_customs(true);
                                                    }}
                                                >Edit Sails</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: '100%',
                                        height: '100%',
                                        "min-width": '0',
                                        "min-height": '0',
                                        background: 'var(--black)',
                                        color: 'var(--white)',
                                        display: PokiController.getInstance().sdk ? 'flex' : 'none',
                                        "justify-content": 'center',
                                        "align-items": 'center',
                                        // padding: '0 12px',
                                        "box-sizing": 'border-box',
                                        "flex-direction": 'column',
                                        gap: '8px',
                                    }}
                                        id="game-over-invite-link">
                                        <div style={{
                                            "text-align": 'center',
                                            "font-weight": '700',
                                        }}>
                                            You know, you'll die less if you bring friends
                                        </div>
                                        <div
                                            style={{
                                                padding: '3px 7px',
                                                ...(
                                                    invite_link() ? {
                                                        "border-bottom": '1px solid var(---white)',
                                                        color: 'var(white)',
                                                    } : {
                                                        background: 'var(--white)',
                                                        color: 'var(--black)'
                                                    }
                                                ),
                                                "border-radius": '50px',
                                                "font-weight": 700,
                                                cursor: 'pointer',
                                            }}
                                            onclick={async () => {
                                                if (invite_link()) {
                                                } else {
                                                    // const inviteURL = CrazyGamesController.getInstance().getInviteLink();
                                                    const inviteURL = await PokiController.getInstance().getInviteLink();

                                                    if (inviteURL) {
                                                        set_invite_link(inviteURL);
                                                        navigator.clipboard.writeText(inviteURL);
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                // invite_link() && CrazyGamesController.getInstance().sdk ?
                                                invite_link() && PokiController.getInstance().sdk ?
                                                    <div style={{
                                                        "text-align": 'center',
                                                    }}>
                                                        <input
                                                            type='text'
                                                            value={invite_link()}
                                                            style={{
                                                                background: 'white',
                                                                "user-select": 'all',
                                                                color: 'var(--black)',
                                                                "border-left": 'none',
                                                                "border-right": 'none',
                                                                "border-top": 'none',
                                                                "border-bottom": '1px solid var(--white)',
                                                                outline: 'none',
                                                            }}
                                                            readOnly
                                                            onClick={function () {
                                                                this.select();
                                                            } as (this: HTMLInputElement) => {}}
                                                            id="invite-link"
                                                        />
                                                        <br />
                                                        <label for="invite-link" style={{
                                                            color: 'var(--white)',
                                                            "font-size": '12px',
                                                        }}>Link Copied!</label>
                                                    </div> :
                                                    'Copy Your Lobby Link'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{
                                display: 'flex',
                                "justify-content": 'center',
                                "align-items": 'center',
                                "margin-top": '4px',
                                "pointer-events": 'all',
                            }}>
                                <div
                                    style={{
                                        width: '300px',
                                        height: '250px',
                                    }}
                                    id="game-over-banner"
                                ></div>
                            </div> */}
                        </div>
                    </div>}
                </>)
            }
        </div >
    );
};

export default App;
