import { color, normalWorld } from 'three/nodes';

import WebGL from 'three/addons/capabilities/WebGL.js';
import WebGPU from 'three/addons/capabilities/WebGPU.js';

import TWEEN from '@tweenjs/tween.js';
import { ACESFilmicToneMapping, AmbientLight, DirectionalLight, DoubleSide, HemisphereLight, MathUtils, Mesh, PMREMGenerator, PerspectiveCamera, PlaneGeometry, Scene, ShaderMaterial, Texture, Vector3, WebGLRenderTarget, WebGLRenderer } from 'three';
import { BatchedParticleRenderer } from 'three.quarks';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { EffectComposer, OutputPass, RenderPixelatedPass, Sky, Water } from 'three/examples/jsm/Addons.js';
import { AnalyticsController } from './Controllers/AnalyticsController';
import { AudioController } from './Controllers/AudioController';
import { CannonVolleyController } from './Controllers/CannonVolleyController';
import { FoamController } from './Controllers/FoamController';
import { ImpactController } from './Controllers/ImpactController';
import { IslandInstanceController } from './Controllers/IslandInstanceController';
import { NetController } from './Controllers/NetController';
import { NotifierController } from './Controllers/NotifierController';
import { ShipInstanceController } from './Controllers/ShipInstanceController';
import { SplashController } from './Controllers/SplashController';
import { Player } from './Templates/Player';
import { boundsFrag } from './commons/utils/bounds.frag';
import { waterFrag } from './commons/utils/water.frag';
import { BUCKETS_X, BUCKET_WIDTH, GAME_SIZE, isChromeBook } from './constants';
import { TEXTURE_NAMES, texture_repo } from './texture_repo';

// export const stats = new Stats();

// location.hostname.includes('localhost') && document.body.appendChild(stats.dom);

const StartGame = (parent: string) => {

    console.log('main again?')

    setTimeout(() => {
        const div = document.getElementById('game-over');
        if (div) {
            div.style.opacity = '1';
        }
    }, 1000);

    let camera: PerspectiveCamera;
    let scene: Scene;
    let renderer: WebGLRenderer;
    let controls: OrbitControls;

    let last = performance.now();

    const webGL2Available = WebGL.isWebGL2Available();

    if (webGL2Available === false) {

        document.body.appendChild(WebGPU.getErrorMessage());

        AnalyticsController.getInstance().logWebGLGPUError(webGL2Available);

        throw new Error('No WebGL2 support');
    }

    camera = new PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.01, 500);

    scene = new Scene();
    scene.environmentIntensity = 0.34;

    // @ts-ignore
    scene.backgroundNode = normalWorld.y.mix(color(0x0487e2), color(0xb8e2f5));
    camera.lookAt(0, 1, 0);

    const sunLight = new DirectionalLight(0xFFE499, 5);
    sunLight.position.set(.5, 3, .5);

    const waterColour = 0x1993cd;

    scene.add(new AmbientLight(0xffffff));

    const skyAmbientLight = new HemisphereLight(0x74ccf4, 0, 1);

    scene.add(sunLight);
    scene.add(skyAmbientLight);

    // renderer

    renderer = new WebGLRenderer({
        // antialias: !isChromeBook,
        antialias: true,
        // powerPreference: 'low-power',
        powerPreference: 'high-performance',
        // alpha: false,
        // preserveDrawingBuffer: true,
    });
    // renderer.setPixelRatio(window.devicePixelRatio);
    // const aspectRatio = window.innerWidth / window.innerHeight;

    // const width = isChromeBook ? 800 : window.innerWidth;
    // const height = isChromeBook ? width * aspectRatio : window.innerHeight;
    // renderer.setSize(width, height);

    const domEle = renderer.domElement;
    domEle.setAttribute('tabindex', '0');
    domEle.style.width = window.innerWidth * window.devicePixelRatio + 'px';
    domEle.style.height = window.innerHeight * window.devicePixelRatio + 'px';

    renderer.toneMapping = ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.5;
    document.getElementById(parent)!.appendChild(renderer.domElement);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableRotate = false;
    controls.enablePan = false;
    controls.enableZoom = false;
    controls.minDistance = (window as any).PORTRAIT_ORIENTATION ? 25 : 14;
    controls.maxDistance = (window as any).PORTRAIT_ORIENTATION ? 25 : 14;
    camera.position.copy({ x: -2, y: 9.899494936611664, z: 11.899494936611665 });
    controls.update();

    // Particles
    const batchSystem = new BatchedParticleRenderer();
    scene.add(batchSystem);

    let lastParticleUpdate = 0;
    const PARTICLE_SYSTEM_DT = 1000 / 20;

    // Player
    let player = new Player(scene, controls, camera, batchSystem);

    // Net Controller
    let net = NetController.getInstance(scene, player)!;

    net.connect();

    // Splash controller
    SplashController.getInstance(scene, batchSystem);

    // Foam controller
    FoamController.getInstance(scene, batchSystem);

    FoamController.triggerFoam(-40000, -40000);
    FoamController.triggerFoam(-40000, -40000);
    FoamController.triggerFoam(-40000, -40000);
    FoamController.triggerFoam(-40000, -40000);

    CannonVolleyController.getInstance(scene);

    ImpactController.getInstance(scene, batchSystem);

    ImpactController.triggerImpact(-40000, -40000, -40000, false);
    ImpactController.triggerImpact(-40000, -40000, -40000, false);
    ImpactController.triggerImpact(-40000, -40000, -40000, false);
    ImpactController.triggerImpact(-40000, -40000, -40000, false);

    // FoamController.getInstance().createFoamSpawner(player.mesh);

    // In-game Notifier
    NotifierController.getInstance();

    // Audio Controller
    AudioController?.instance?.stopMainMenuMusic?.();

    player.instanceController = ShipInstanceController.getInstance();
    // player.instanceIX = player.instanceController.getInstanceID();

    net.islandInstanceController = IslandInstanceController.getInstance();

    scene.add(player.instanceController.instances);
    scene.add(net.islandInstanceController.instances);

    //@ts-ignore
    AudioController.instance = null;
    const audioController = AudioController.getInstance(player.audioListener);

    AudioController?.instance?.loadBossGameOverAuds?.()?.then?.(() => {
        const instance = AudioController?.instance;

        if (instance) {
            if (instance.shouldPlayBoss) {
                instance.playBossMusic();
            }
        }
    })?.catch?.(err => {
        console.error(err);
    });

    player.audioController = audioController;

    window.addEventListener('click', () => {
        audioController.resumeContext();
    });

    SplashController.triggerSplash(-40000, -40000, false);
    SplashController.triggerSplash(-40000, -40000, false);
    SplashController.triggerSplash(-40000, -40000, false);
    SplashController.triggerSplash(-40000, -40000, false);

    // AudioController.getInstance().stopMainMenuMusic();

    /** @type {HTMLAudioElement} */
    const ambienceSnd = document.getElementById('ambience') as HTMLAudioElement;
    ambienceSnd.play();

    const WATER_SIZE = GAME_SIZE * 1.5;
    // const SIZE = GAME_SIZE * 1.5;
    // const SIZE = BUCKET_WIDTH;
    const SIZE = BUCKET_WIDTH * 9;
    // const SIZE = GAME_SIZE;
    // const HALF_SIZE = GAME_SIZE * 0.5;
    const BUCKETS_PER_AXIS = BUCKETS_X;
    const TOTAL_TILES = BUCKETS_PER_AXIS * BUCKETS_PER_AXIS;
    // const TILE_SIZE = GAME_SIZE / BUCKETS_PER_AXIS;
    const HALF_TILE_SIZE = SIZE * 0.5;
    const START_X = -HALF_TILE_SIZE * Math.round(BUCKETS_PER_AXIS * 0.5);
    // const TEXTURE_SIZE = 128 * 2;
    const TEXTURE_SIZE = 128 * 4;
    // const TEXTURE_SIZE = 128;

    const waterGeometry = new PlaneGeometry(SIZE, SIZE, 16, 16);

    const water = new Water(waterGeometry,
        {
            // FIXME: the texture size is why there's a lot of stuttering
            textureWidth: TEXTURE_SIZE,
            textureHeight: TEXTURE_SIZE,
            waterNormals: texture_repo[TEXTURE_NAMES.WATER_NORMAL],
            eye: new Vector3(1, 4, 2),
            sunDirection: new Vector3(),
            sunColor: waterColour,
            waterColor: waterColour,
            // distortionScale: 3.7,
            distortionScale: 100,
            fog: scene.fog !== undefined,
        }
    );

    console.log('water:', water);

    const waterUniforms = water.material.uniforms;
    console.log('wu:', waterUniforms);
    // waterUniforms.distortionScale.value = 0.10;
    waterUniforms.distortionScale.value = 1;
    waterUniforms.size.value = 10;
    // waterUniforms.dir1 = {
    //     value: new Vector4(1000, 1000, 1000, 1000),
    // };
    // waterUniforms.dir2 = {
    //     value: new Vector4(1000, 1000, 1000, 1000),
    // };

    water.rotation.x = -Math.PI / 2;

    water.material.fragmentShader = waterFrag;

    scene.add(water);

    const sun = new Vector3();

    const sky = new Sky();
    sky.scale.setScalar(GAME_SIZE * 1.5);
    scene.add(sky);

    const skyUniforms = sky.material.uniforms;

    skyUniforms['turbidity'].value = 10;
    skyUniforms['rayleigh'].value = 2;
    skyUniforms['mieCoefficient'].value = 0.005;
    skyUniforms['mieDirectionalG'].value = 0.8;

    const parameters = {
        elevation: 2,
        azimuth: 180
    };
    const pmremGenerator = new PMREMGenerator(renderer as any);
    const sceneEnv = new Scene();

    let renderTarget: WebGLRenderTarget<Texture> | undefined;

    const diff = (WATER_SIZE * 0.5) - (GAME_SIZE * 0.5);
    let firstRatioBound = (diff / WATER_SIZE);
    let secondRatioBound = ((WATER_SIZE - diff) / WATER_SIZE);

    const shaderMat = new ShaderMaterial({
        name: 'BoundsShaderMat',
        uniforms: {
            time: {
                value: 0
            },
            p_Pos: {
                value: new Vector3(1, 0, 0),
            },
            firstRatioBound: {
                value: firstRatioBound,
            },
            secondRatioBound: {
                value: secondRatioBound,
            },
            MAX_DIST: {
                value: 0.07,
            }
        },
        transparent: true,
        // depthTest: false,
        vertexShader: /* glsl */`
            varying vec2 pos;
            varying vec2 uVu;

            void main() {
                uVu = uv;
                pos = position.xz;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: boundsFrag(),
        side: DoubleSide,
    });

    const boundsPlain = new Mesh(new PlaneGeometry(WATER_SIZE, WATER_SIZE), shaderMat);

    // boundsPlain.position.set(0, 0.034, 0);
    boundsPlain.position.set(-0.7, 0.034, -0.5);
    // boundsPlain.position.set(-4, 0.034, -1);
    boundsPlain.rotation.x = Math.PI * 0.5;
    // boundsPlain.position.y = 0.1;

    scene.add(boundsPlain);

    // const waterTiles = new Map();

    // for (let ix = 0; ix < TOTAL_TILES; ix++) {
    //     const x = ix % BUCKETS_PER_AXIS;
    //     const y = (ix - x) / BUCKETS_PER_AXIS;

    //     const BIN_KEY = generateHashKey(x, y);

    //     const HALF_BUCKETS_PER_AXIS = Math.round(BUCKETS_PER_AXIS * 0.5);

    //     const group = net.groups.get(BIN_KEY);

    //     // console.log('group water tile:', group)

    //     if (x == HALF_BUCKETS_PER_AXIS && y == HALF_BUCKETS_PER_AXIS) {
    //         waterTiles.set(BIN_KEY, water);
    //         group?.add(water);
    //         // water.visible = false;
    //         continue;
    //     }

    //     const waterTile = new Mesh(waterGeometry, water.material);
    //     // const waterTile = water.clone();

    //     waterTile.position.x = x * SIZE + START_X;
    //     waterTile.position.z = y * SIZE + START_X;

    //     waterTile.rotation.copy(water.rotation);

    //     group?.add(waterTile);
    //     waterTiles.set(BIN_KEY, waterTile);

    //     // waterTile.visible = false;

    //     // group && scene.add(group);
    // }

    function updateSun() {
        const phi = MathUtils.degToRad(90 - parameters.elevation);
        const theta = MathUtils.degToRad(parameters.azimuth);

        sun.setFromSphericalCoords(1, phi, theta);

        sky.material.uniforms['sunPosition'].value.copy(sun);
        // waterTiles.forEach(water => {
        water.material.uniforms['sunDirection'].value.copy(sun).normalize();
        // });

        if (renderTarget !== undefined) renderTarget.dispose();

        sceneEnv.add(sky);
        renderTarget = pmremGenerator.fromScene(sceneEnv);
        scene.add(sky);

        scene.environment = renderTarget.texture;
    }

    updateSun();

    // Post Processing
    const composer = new EffectComposer(renderer);
    // const renderPixelatedPass2 = new RenderPixelatedPass(7, sceneEnv, camera);
    // composer.addPass(renderPixelatedPass2);
    const renderPixelatedPass = new RenderPixelatedPass(2, scene, camera);
    composer.addPass(renderPixelatedPass);

    const outputPass = new OutputPass();
    composer.addPass(outputPass);

    window.addEventListener('resize', onWindowResize);

    function onWindowResize() {

        const aspectRatio = window.innerWidth / window.innerHeight;
        const width = isChromeBook ? 800 : window.innerWidth;
        const height = isChromeBook ? width * aspectRatio : window.innerHeight;
        camera.aspect = aspectRatio;
        camera.updateProjectionMatrix();

        renderer.setSize(width, height);
        composer.setSize(width, height);

        const domEle = renderer.domElement;
        if (isChromeBook) {
            domEle.style.width = window.innerWidth + 'px';
            domEle.style.height = window.innerHeight + 'px';
        }
    }

    onWindowResize();

    window.onblur = () => {
        player.resetInputs();
    };

    function animate() {
        // stats.begin();

        const now = performance.now();
        const DT = now - last;
        const DT_RATIO = DT / 1000;
        last = now;

        if (DT_RATIO > 1) {
            return;
        }

        lastParticleUpdate += DT;

        //#region Particle Updates
        if (lastParticleUpdate >= PARTICLE_SYSTEM_DT) {
            batchSystem.update(lastParticleUpdate);
            lastParticleUpdate = 0;
        }

        net.update(DT);
        player.update(DT, now);

        const pPos = player.mesh.position;

        water.position.x = pPos.x;
        water.position.z = pPos.z;

        const pos = player.mesh.position;

        // const wkey = getBucketName(pos.x, pos.z, -START_X, HALF_SIZE, HALF_SIZE);
        // const binP_X = player.mesh

        // player.prevNeighbouringBins.forEach(wkey => {
        //     const waterTile = waterTiles.get(wkey);

        //     if (waterTile) {
        //         waterTile.visible = false;
        //     }
        // });
        // player.currentNeighbouringBins.forEach(wkey => {
        //     const waterTile = waterTiles.get(wkey);

        //     if (waterTile) {
        //         waterTile.visible = true;
        //     }
        // });

        // @ts-ignore
        // window.wkey = player.currentBin;

        // const w = waterTiles.get(player.currentBin);

        // if (w) {
        //     w.visible = true;
        // }

        // waterTiles.forEach(water => {
        water.material.uniforms['time'].value += (1.0 / 60.0) * DT_RATIO * 7;
        // });
        shaderMat.uniforms.time.value = performance.now();
        shaderMat.uniforms.p_Pos.value = new Vector3((pos.x + WATER_SIZE * 0.5) / WATER_SIZE, 0, (pos.z + WATER_SIZE * 0.5) / WATER_SIZE);
        // shaderMat.uniforms.firstRatioBound.value = firstRatioBound;
        // shaderMat.uniforms.secondRatioBound.value = secondRatioBound;


        TWEEN.update();

        ShipInstanceController?.instance?.prepareToUpdate();
        IslandInstanceController?.instance?.prepareToUpdate();

        if (player.pixelated) {
            composer.render();
        } else {
            renderer.render(scene, camera);
        }

        if (player.fps > -1) {
            const fps = 1000 / (performance.now() - now);
            player.fps = (fps + player.fps) * 0.5;
        } else {
            player.fps = 1000 / (performance.now() - now);
        }
    }

    renderer.setAnimationLoop(animate);

    // @ts-ignore Observe a scene or a renderer
    if (typeof __THREE_DEVTOOLS__ !== 'undefined') {
        // @ts-ignore
        __THREE_DEVTOOLS__.dispatchEvent(new CustomEvent('observe', { detail: scene }));
        // @ts-ignore
        __THREE_DEVTOOLS__.dispatchEvent(new CustomEvent('observe', { detail: renderer }));
    }

    return {
        scene,
        camera,
        renderer,
        net,
        cleanup: () => {
            console.log('cleanup!:')
            scene.traverse(child => {
                // @ts-ignore
                if (child?.dispose) {
                    // @ts-ignore
                    child.dispose();
                }
            });
            scene.clear();
            scene.removeFromParent();
            renderer.setAnimationLoop(null);
            renderer.dispose();
            composer.dispose();

            net?.disconnect?.();

            player.destroy();
            AudioController.instance.destroy();

            // @ts-ignore
            SplashController.instance = undefined;
            // @ts-ignore
            FoamController.instance = undefined;
            // @ts-ignore
            CannonVolleyController.instance = undefined;
            // @ts-ignore
            ImpactController.instance = undefined;
            // @ts-ignore
            ImpactController.instance = undefined;
            // @ts-ignore
            NotifierController.instance = undefined;
            // @ts-ignore
            AudioController.instance = undefined;
            // @ts-ignore
            NetController.instance = undefined;
            // @ts-ignore
            ShipInstanceController.instance = undefined;
            // @ts-ignore
            IslandInstanceController.instance = undefined;
        }
    }
}

export default StartGame;
