import { mute, set_mute } from "../../App";
import { EventBusNames } from "../constants";
import { EventBus } from "../EventBus";
import { AudioController } from "./AudioController";

export class GMSDKController {
    public static instance: GMSDKController;
    public static track = true;

    public static getInstance() {
        if (this.instance) {
            return this.instance;
        } else {
            this.instance = new GMSDKController();

            return this.instance;
        }
    }

    sdk: any;
    oldMute = mute();

    private constructor() {
        (window as any).GMSDKController = GMSDKController;

        this.sdk = (window as any).sdk;
    }

    showBanner() {
        this?.sdk?.showBanner?.();
    }
    // showAd(type?: 'rewarded-ad') {
    //     // this?.gdsdk?.showAd?.(type);
    //     this?.gdsdk?.showBanner?.();
    // }

    adStart() {
        this.oldMute = mute();
        AudioController.getInstance().mute();

        console.log('GMSK:', 'muted');

        return new Promise<void>((res, rej) => {
            EventBus.emit(EventBusNames.AD_START);
            res();
        });
    }
    adDone() {
        if (this.oldMute) {
            AudioController.getInstance().mute();
        } else {
            AudioController.getInstance().unmute();
        }

        console.log('GMSK:', 'muted:', this.oldMute);

        return new Promise<void>((res, rej) => {
            EventBus.emit(EventBusNames.AD_DONE);
            res();
        });
    }
}
