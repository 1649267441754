export const ReloadTimeIconSVG = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3638_1399)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.645 1.929C5.38357 1.929 1.929 5.38357 1.929 9.645C1.929 13.9065 5.38357 17.361 9.645 17.361C13.9065 17.361 17.361 13.9065 17.361 9.645C17.361 5.38357 13.9065 1.929 9.645 1.929ZM0 9.645C0 4.31821 4.31821 0 9.645 0C14.9717 0 19.29 4.31821 19.29 9.645C19.29 14.9717 14.9717 19.29 9.645 19.29C4.31821 19.29 0 14.9717 0 9.645ZM9.47949 4.58847C10.0122 4.58847 10.444 5.02029 10.444 5.55297V10.3438L13.8457 11.637C14.3437 11.8262 14.5939 12.3833 14.4045 12.8812C14.2153 13.3792 13.6582 13.6294 13.1603 13.44L9.13681 11.9106C8.76248 11.7683 8.51499 11.4095 8.51499 11.009V5.55297C8.51499 5.02029 8.9468 4.58847 9.47949 4.58847Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_3638_1399">
                    <rect width="19.29" height="19.29" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}