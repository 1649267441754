export const SBGameLogo = ({ style }: any) => {
    return <svg style={{
        width: window.innerWidth <= 768 ? '43vw' : '59vw',
        ...style,
    }} width="788" height="94" viewBox="0 0 788 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.5978 93.2817C31.9045 93.2817 27.2538 92.855 22.6458 92.0017C18.1231 91.2337 14.1125 90.2097 10.6138 88.9297C7.11515 87.6497 4.59781 86.3697 3.06181 85.0897V58.3377C6.30448 60.1297 9.93115 61.751 13.9418 63.2017C17.9525 64.6523 22.0058 65.8043 26.1018 66.6577C30.2831 67.4257 34.2511 67.8097 38.0058 67.8097C43.2111 67.8097 45.8138 66.615 45.8138 64.2257C45.8138 63.031 45.2591 62.0497 44.1498 61.2817C43.1258 60.4283 41.2911 59.5323 38.6458 58.5937C36.0005 57.655 32.2458 56.4603 27.3818 55.0097C21.7498 53.2177 16.9285 51.3403 12.9178 49.3777C8.99248 47.415 5.96315 44.8977 3.82981 41.8257C1.78181 38.7537 0.757812 34.6577 0.757812 29.5377C0.757812 20.663 4.29915 13.5377 11.3818 8.16166C18.4645 2.78566 28.8325 0.0976562 42.4858 0.0976562C45.2165 0.0976562 48.1178 0.225657 51.1898 0.481659C54.3471 0.652323 56.8645 0.908323 58.7418 1.24966L74.4858 11.8737L68.0858 31.3297L35.3178 23.0097C31.9045 23.0097 30.1978 24.1617 30.1978 26.4657C30.1978 28.2577 31.2645 29.751 33.3978 30.9457C35.6165 32.055 39.5845 33.591 45.3018 35.5537C52.8965 38.1137 58.9125 40.759 63.3498 43.4897C67.8725 46.135 71.1151 49.1643 73.0778 52.5777C75.0405 55.991 76.0218 60.0443 76.0218 64.7377C76.0218 73.271 72.7365 80.183 66.1658 85.4737C59.5951 90.679 49.7392 93.2817 36.5978 93.2817Z" fill="#ECECEC" />
        <path d="M129.885 93.2817C114.951 93.2817 104.199 90.295 97.6288 84.3217C91.0581 78.263 87.7728 68.5777 87.7728 55.2657V21.9857L87.9008 9.05766L87.7728 1.37766H116.445V48.8657C116.445 52.791 116.743 56.2897 117.341 59.3617C117.938 62.3483 119.218 64.695 121.181 66.4017C123.143 68.1083 126.13 68.9617 130.141 68.9617C134.237 68.9617 137.266 68.1937 139.229 66.6577C141.191 65.1217 142.471 62.8603 143.069 59.8737C143.666 56.887 143.965 53.2177 143.965 48.8657V1.37766H171.997V55.2657C171.997 63.9697 170.589 71.1377 167.773 76.7697C164.957 82.3163 160.477 86.455 154.333 89.1857C148.189 91.9163 140.039 93.2817 129.885 93.2817Z" fill="#ECECEC" />
        <path d="M188.613 92.0017V21.9857L186.053 9.18566L188.613 1.50566H214.341L240.197 33.7617L246.341 44.3857H247.621V1.50566H277.189V9.18566L275.909 21.9857V92.0017H250.437L218.309 48.2257H217.029V55.9057L218.309 92.0017H188.613Z" fill="#ECECEC" />
        <path d="M295.113 92.0017V21.8577L292.553 9.05766L295.113 1.37766H343.753C353.395 1.37766 361.118 3.25499 366.921 7.00966C372.723 10.7643 375.625 16.4817 375.625 24.1617C375.625 28.343 374.857 31.799 373.321 34.5297C371.87 37.175 369.993 39.4363 367.689 41.3137C370.59 41.9963 373.321 43.2763 375.881 45.1537C378.441 46.9457 380.531 49.3777 382.153 52.4497C383.774 55.4363 384.585 59.1483 384.585 63.5857C384.585 72.5457 381.939 79.543 376.649 84.5777C371.443 89.527 363.038 92.0017 351.433 92.0017H295.113ZM323.273 36.5777H338.377C341.278 36.5777 343.369 36.151 344.649 35.2977C345.929 34.359 346.569 32.951 346.569 31.0737C346.569 29.111 345.63 27.7457 343.753 26.9777C341.875 26.1243 339.657 25.6977 337.097 25.6977H323.273V36.5777ZM323.273 67.6817H345.033C348.446 67.6817 350.835 67.1697 352.201 66.1457C353.566 65.0363 354.249 63.5857 354.249 61.7937C354.249 57.6977 351.603 55.6497 346.313 55.6497H323.273V67.6817Z" fill="#ECECEC" />
        <path d="M391.028 92.0017L397.172 70.3697V54.1137L406.644 50.2737L424.564 1.50566H463.092L484.98 58.8497L494.836 77.0257V92.0017H467.06L460.66 72.9297H425.972L420.468 92.0017H391.028ZM434.42 50.5297H453.62L445.172 25.0577H442.484L434.42 50.5297Z" fill="#ECECEC" />
        <path d="M506.363 92.0017V22.3697L503.803 9.44166L506.363 1.37766H534.523V16.7377L533.243 37.0897H537.083L556.411 1.37766H587.003V9.05766L561.659 45.0257L583.163 71.6497L595.835 84.3217V92.0017H561.915L539.899 60.6417L534.523 61.9217V76.6417L535.803 84.3217L534.523 92.0017H506.363Z" fill="#ECECEC" />
        <path d="M607.363 92.0017L604.803 84.3217L607.363 71.5217V1.37766H673.283L679.683 5.21766L671.235 26.9777L659.715 25.6977H635.779V35.9377H667.395V57.0577H649.731L635.779 55.7777V67.6817H671.235L686.467 83.8097L678.915 92.0017H607.363Z" fill="#ECECEC" />
        <path d="M696.738 92.0017V21.9857L694.178 9.18566L696.738 1.37766H740.13C746.188 1.37766 752.034 1.975 757.666 3.16966C763.383 4.27899 768.46 6.41232 772.898 9.56966C777.42 12.727 781.005 17.2497 783.65 23.1377C786.295 29.0257 787.618 36.663 787.618 46.0497C787.618 55.4363 786.252 63.159 783.522 69.2177C780.876 75.191 777.293 79.8417 772.77 83.1697C768.247 86.4977 763.17 88.8017 757.538 90.0817C751.906 91.3617 746.103 92.0017 740.13 92.0017H696.738ZM725.154 67.6817H734.882C739.917 67.6817 744.14 67.127 747.554 66.0177C751.052 64.9083 753.698 62.8177 755.49 59.7457C757.282 56.5883 758.178 52.023 758.178 46.0497C758.178 39.991 757.239 35.5537 755.362 32.7377C753.57 29.8363 750.925 27.959 747.426 27.1057C744.013 26.167 739.831 25.6977 734.882 25.6977H725.154V67.6817Z" fill="#ECECEC" />
    </svg>
}
