export const Banner300x250 = () => {
    return <div
        style={{
            // width: '300px',
            // height: '250px',
            // "margin-top": '4px',
            // scale: '0.6',
            // translate: '20% -20%',
            // background: 'red',
        }}
    >
        <div
            style={{
                width: '300px',
                height: '250px',
                "margin-top": '4px',
                // scale: '0.6',
                // translate: '20% -20%',
                // background: 'red',
            }}
            id="menu-banner"
            onClick={() => {
                // TODO: add analytics event for banner clicked
            }}
        >
            {/* Banner */}
        </div>
    </div>
}