/* @refresh reload */
import { render } from 'solid-js/web';

import { AudioLoader, RepeatWrapping } from 'three';
import App, { set_username } from './App';
import { AUDIO_NAMES, audio_repo } from './game/audio_repo';
import { LocalStorageKeys } from './game/commons/enums/LocalStorageKeys';
import { text_repo, TextType } from './game/commons/utils/getCachedText';
import { loadAndCacheTexture } from './game/commons/utils/loadAndCacheTexture';
import { loadGLTF } from './game/commons/utils/loadGLTF';
import { loadSailTexture } from './game/commons/utils/loadSailTexture';
import { CrazyGamesController } from './game/commons/vendors/CrazyGamesController';
import { PokiController } from './game/commons/vendors/PokiController';
import { DEFAULT_SERVER_OPTIONS_SEARCH, SERVER_OPTION_NAMES, SERVER_OPTIONS } from './game/constants';
import { AnalyticsController } from './game/Controllers/AnalyticsController';
import { LocalStorageController } from './game/Controllers/LocalStorageController';
import { NetController } from './game/Controllers/NetController';
import { EventBus } from './game/EventBus';
import { MESH_NAMES } from './game/ship_repo';
import { TEXTURE_NAMES } from './game/texture_repo';

// if (window.location !== window.parent.location || window.location.hostname.includes('localhost')) {
//     // Iframed

// const rotationPrompt = new DeviceRotationPrompt({
//     mobileDetect: true,
//     orientation: DeviceOrientation.Landscape,
//     zIndex: 10000,
// });

const analytics = AnalyticsController.getInstance();
analytics.logLoadingStarted();
console.log('started loading assets');

let totalLoaded = 0;
let TOTAL_ITEMS = 0;
export const audioLoader = new AudioLoader();

const preloadItems = [
    loadGLTF('assets/models/ship.glb', MESH_NAMES.BASIC_1, () => {
        updateProgressBar();
    }),
    loadGLTF('assets/models/chest.glb', MESH_NAMES.CRATE, (gltf) => {
        updateProgressBar();
        // console.log('chest:', gltf);
    }),
    loadGLTF('assets/models/island.glb', MESH_NAMES.ISLAND, (gltf) => {
        updateProgressBar();
        // console.log('island:', gltf);
    }),
    loadGLTF('assets/models/arrow-guides.glb', MESH_NAMES.ARROW_GUIDES, (gltf) => {
        updateProgressBar();
        // console.log('island:', gltf);
    }),
    loadGLTF('assets/models/firing-guides.glb', MESH_NAMES.FIRING_GUIDES, (gltf) => {
        updateProgressBar();
        // console.log('island:', gltf);
    }),
    ...(!(window as any).IS_CRAZY_GAMES
        && !(window as any).IS_POKI
        && !(window as any).IS_NEWGROUNDS
        && !(window as any).IS_ITCHIO
        && !(window as any).IS_GD
        && !(window as any).IS_GAMEJOLT ? [loadGLTF('assets/models/Sponsor.glb', MESH_NAMES.SPONSOR_LOGO, (gltf) => {
            updateProgressBar();
            // console.log('island:', gltf);
        })] : []),
    loadGLTF('assets/models/KIGA.glb', MESH_NAMES.KIGA_GAMES, (gltf) => {
        updateProgressBar();
        // console.log('island:', gltf);
    }),
    loadGLTF('assets/models/sails/default/default-sails.gltf', MESH_NAMES.BASIC_1_SAILS_DEFAULT, () => {
        updateProgressBar();
    }),
    loadGLTF('assets/models/CTA.glb', MESH_NAMES.CTA_TEXT, (gltf) => {
        console.log('chest_pickup_text:', gltf?.scene);
        text_repo[TextType.PICK_ME] = gltf?.scene as any;
        // (gltf!.scene.children[0] as Mesh).material = textMaterial;
        updateProgressBar();
    }),
    // loadGLTF('assets/models/crate.glb', SHIP_NAMES.CRATE, () => {
    //     updateProgressBar();
    // }),
    loadAndCacheTexture('waternormals.jpg', TEXTURE_NAMES.WATER_NORMAL, texture => {
        texture.wrapS = texture.wrapT = RepeatWrapping;

        return texture;
    }),
    loadAndCacheTexture('form-50p.png', TEXTURE_NAMES.FOAM),
    loadAndCacheTexture('splash-paddle.png', TEXTURE_NAMES.SPLASH_PADDLE),
    loadAndCacheTexture('splash.png', TEXTURE_NAMES.SPLASH),
    ...[
        ...((window as any).IS_CRAZY_GAMES ? [
            TEXTURE_NAMES.CRAZY_GAMES_WHITE,
            TEXTURE_NAMES.CRAZY_GAMES_BLACK,
            TEXTURE_NAMES.CRAZY_GAMES_PURPLE,
        ] : []),
        TEXTURE_NAMES.CROSS,
        TEXTURE_NAMES.ZAMBIA,
        TEXTURE_NAMES.BELGIUM,
        TEXTURE_NAMES.GERMANY,
        TEXTURE_NAMES.USA,
        TEXTURE_NAMES.SA,
    ].map(textureName => loadSailTexture(textureName, textureName)),
    ...[
        [AUDIO_NAMES.HELP_WELCOME, 'Start_help_1.m4a'],
        [AUDIO_NAMES.HELP_MOVEMENT, 'Start_help_2.m4a'],
        [AUDIO_NAMES.HELP_FIRING, 'Start_help_3.m4a'],
        [AUDIO_NAMES.HELP_OUTRO, 'Start_help_4.m4a'],
        [AUDIO_NAMES.HELP_CRUISE_BARRELS, 'torpedo_help.m4a'],
        [AUDIO_NAMES.HELP_MOBILE_NO_SUPPORT, 'no_mobile.m4a'],
        [AUDIO_NAMES.CHAMPION_OF_THE_SEA, 'champion.m4a'],
        [AUDIO_NAMES.MAKE_THEM_REMEMBER, 'remember.m4a'],
        [AUDIO_NAMES.GET_BACK, 'get-back.m4a'],
    ].map(([key, slug]) => {
        return new Promise<void>(res => {
            audioLoader.load('snd/help-voice/' + slug, function (buffer) {
                updateProgressBar();
                res();
                audio_repo[key] = buffer;
            });
        })
    }),
    ...[
        [AUDIO_NAMES.SHIP_SINK, 'ship-sink-freq-500.m4a'],
        [AUDIO_NAMES.GUN_FIRE, 'gun-fire-freq-500.m4a'],
        [AUDIO_NAMES.GUN_FIRE_TAIL, 'gun-fire-tail-freq-500.m4a'],
        [AUDIO_NAMES.NO_GUN_FIRE, 'no-fire.m4a'],
        [AUDIO_NAMES.LEVEL_UP_SHOUT, 'level-up-shout-2.m4a'],
        [AUDIO_NAMES.PICK_UP, 'pickup.m4a'],
        // [AUDIO_NAMES.MAIN_MENU, 'main-menu.m4a'],
        // [AUDIO_NAMES.GAME_OVER, 'gameover.m4a'],
        // [AUDIO_NAMES.BOSS, 'boss.m4a'],
        [AUDIO_NAMES.SHIP_HIT, 'hit-crack.m4a'],
        // [AUDIO_NAMES.PICK_UP, 'switch21.ogg'],
    ].map(([key, slug]) => {
        return new Promise<void>(res => {
            audioLoader.load('snd/' + slug, function (buffer) {
                updateProgressBar();
                res();
                audio_repo[key] = buffer;
                console.log('Key:', key)
            });
        })
    }),
    ...(window.IS_CRAZY_GAMES ? [
        loadGLTF('assets/models/CrazyGames.glb', MESH_NAMES.CRAZY_GAMES, (gltf) => {
            updateProgressBar();
        }),
    ] : []),
    ...(window.IS_POKI ? [
        loadGLTF('assets/models/poki.glb', MESH_NAMES.POKI, () => {
            updateProgressBar();
        }),
    ] : []),
    ...(window.IS_NEWGROUNDS ? [
        loadGLTF('assets/models/newgrounds.glb', MESH_NAMES.NEWGROUNDS, () => {
            updateProgressBar();
        }),
    ] : []),
    ...(window.IS_ITCHIO ? [
        loadGLTF('assets/models/itchio.glb', MESH_NAMES.ITCHIO, () => {
            updateProgressBar();
        }),
    ] : []),
    ...(window.IS_GAMEJOLT ? [
        loadGLTF('assets/models/gamejolt.glb', MESH_NAMES.GAMEJOLT, () => {
            updateProgressBar();
        }),
    ] : []),
    ...(window.IS_IOGAMES ? [
        loadGLTF('assets/models/iogames.glb', MESH_NAMES.IOGAMES, () => {
            updateProgressBar();
            console.log('created IOGAMES')
        }),
    ] : []),
];

TOTAL_ITEMS = preloadItems.length;

console.log('TOTAL_ITEMS:', TOTAL_ITEMS);

(window as any).EventBus = EventBus;
// (window as any).IS_CRAZY_GAMES && await new Promise(res => {
//     const intervalID = setInterval(() => {
//         const CG = (window as any).CrazyGames;

//         if (CG) {
//             console.log('check:');
//             clearInterval(intervalID);
//             res(CG.SDK.init());
//         }
//     }, 140);
// });
(window as any).IS_POKI && await new Promise(res => {
    const intervalID = setInterval(() => {
        const _POKI = (window as any).PokiSDK;

        if (_POKI) {
            console.log('check:');
            clearInterval(intervalID);
            res(_POKI.init());
        }
    }, 140);
});
(window.IS_GD) && await new Promise<void>(res => {
    const intervalID = setInterval(() => {
        if (window.GDSDK_READY) {
            console.log('check:');
            clearInterval(intervalID);
            res();
        }
    }, 140);
});
(window.IS_GM) && await new Promise<void>(res => {
    const intervalID = setInterval(() => {
        if (window.GMSDK_READY) {
            console.log('check:');
            clearInterval(intervalID);
            res();
        }
    }, 140);
});

console.log('Start process');

const root = document.getElementById('root');

function updateProgressBar() {
    ++totalLoaded;
    const bar = document.getElementById('progress-bar');

    if (bar) {
        bar.style.width = `${Math.min(100, (totalLoaded || 1) / TOTAL_ITEMS * 100)}%`;
    }
}
const pingServer = async (option: SERVER_OPTION_NAMES) => {
    let now = performance.now();

    let dt = 0;

    let lowest = Number.MAX_SAFE_INTEGER;
    let highest = -1;

    console.log('option:', option)

    const check = () => {
        dt = performance.now() - now;
        if (dt < lowest) {
            // 
            lowest = dt;
        }
        if (dt > highest) {
            highest = dt;
        }

        now = performance.now();
    };

    await Promise.allSettled([
        fetch(`https://${SERVER_OPTIONS.get(option)?.url}/ping`).then(() => {
            check();
        }),
        fetch(`https://${SERVER_OPTIONS.get(option)?.url}/ping`).then(() => {
            check();
        }),
        fetch(`https://${SERVER_OPTIONS.get(option)?.url}/ping`).then(() => {
            check();
        }),
        fetch(`https://${SERVER_OPTIONS.get(option)?.url}/ping`).then(() => {
            check();
        }),
        fetch(`https://${SERVER_OPTIONS.get(option)?.url}/ping`).then(() => {
            check();
        }),
    ]);

    return {
        option,
        lowest,
        highest,
        midRange: (lowest + highest) * 0.5,
    };
};
const multiPing = async () => {
    const perfs = await Promise.all(DEFAULT_SERVER_OPTIONS_SEARCH.map(pingServer));

    perfs.sort((a, b) => a.midRange - b.midRange);

    const mainOption = perfs[0];

    NetController.DEFAULT_SERVER_OPTION = mainOption.option;

    console.log('mainOPtion:', mainOption.option)
    AnalyticsController.getInstance().logInitialPing(mainOption.midRange, mainOption.option);
}

async function start() {
    console.log('Starting game!');
    if ((window as any).IS_CRAZY_GAMES && ['local', 'crazygames'].includes((window as any)?.CrazyGames?.SDK?.environment)) {
        const CG = (window as any).CrazyGames;

        // await CG.SDK.init();
        const CGC = CrazyGamesController.getInstance();
        if (CG.SDK.user.isUserAccountAvailable) {
            // Get user name
            const user: { username: string } = await CGC.getUser();

            console.log('user data:', CG.SDK.user.systemInfo);

            if ([
                'US',
                'CA',
                'AR',
                'BO',
                'BR',
                'CL',
                'CO',
                'EC',
                'GY',
                'PY',
                'PE',
                'SR',
                'UY',
                'VE',
                'MX',
            ].includes(CG?.SDK?.user?.systemInfo?.countryCode)) {
                NetController.DEFAULT_SERVER_OPTION = SERVER_OPTION_NAMES.US_EAST;
            }

            if (NetController.DEFAULT_SERVER_OPTION !== SERVER_OPTION_NAMES.US_EAST) {
                await multiPing();
            }

            if (user && !LocalStorageController.getItem(LocalStorageKeys.USERNAME)) {
                const uName = user.username;

                set_username(uName);
                LocalStorageController.setItem(LocalStorageKeys.USERNAME, uName);
            }
        }
        CrazyGamesController.getInstance().startLoading();
    } else if ((window as any).IS_POKI) {
        // const Poki = PokiController.getInstance();

        const params = new URLSearchParams(location.search);
        const country = params.get('country');

        if ([
            'US',
            'CA',
            'AR',
            'BO',
            'BR',
            'CL',
            'CO',
            'EC',
            'GY',
            'PY',
            'PE',
            'SR',
            'UY',
            'VE',
            'MX',
        ].includes(country as string)) {
            NetController.DEFAULT_SERVER_OPTION = SERVER_OPTION_NAMES.US_EAST;
        }

        // if (NetController.DEFAULT_SERVER_OPTION !== SERVER_OPTION_NAMES.US_EAST) {
        //     await multiPing();
        // }
    }
    else {
        await multiPing();
    }

    console.log('done with multipinging:', NetController.DEFAULT_SERVER_OPTION);

    await Promise.allSettled(preloadItems).then(() => {
        console.log('all settled and loaded');

        try {
            analytics.logLoadingEnded();
            CrazyGamesController.getInstance().stopLoading();
            PokiController.getInstance().stopLoading();
            render(() => <App />, root!);
        } catch(err) {
            console.error(err);
        }
    });
}

start();
// }
// TODO: add a check for when you are local above so that you can continue testing locally
// else {
//     const redirectMessage = document.getElementById('redirect-msg');

//     if (redirectMessage) {
//         redirectMessage.style.display = 'flex';

//         let count = 5;

//         redirectMessage.innerHTML = `Redirecting you to CrazyGames/Sunbaked in ${count} seconds`;

//         setInterval(() => {
//             redirectMessage.innerHTML = `Redirecting you to CrazyGames/Sunbaked in ${--count} seconds`;

//             if (count <= 0) {
//                 location.href = 'https://www.crazygames.com/game/sunbaked';
//             }
//         }, 1000);
//     }
// }
